import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import { useState, useEffect, useRef } from "react";
// import mhz from '../assets/bid.png';
import gh from "../assets/godhand.jpg";
import nova from "../assets/nova.png";
import erdem from "../assets/erdem.svg";
// import tmll from '../assets/tmll.svg';
// import available from '../assets/bolomjtoi.svg';
// import unavailable from '../assets/bolomjgui.svg';
// import availableb from '../assets/bolomjtoib.svg';
// import unavailableb from '../assets/bolomjguib.svg';
// import sbdbg from '../assets/sbd.png';
// import sat from '../assets/sat.png';
// import ielts from '../assets/ielts.png';
// import topik from '../assets/topik.png';
import { useContext } from "react";
import Comment from "./comment";
import axios from "axios";
export const Activities = () => {
  const mergejil = [];
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  const location = useLocation();
  const bairshil = location.pathname;
  useEffect(() => {
    document.title = "Tmull ・ Шүдний эмч";

    const dataRetriever = async () => {
      try {
        const response = await axios.get(
          `https://tmull-be.vercel.app/prof/${bairshil.split("/")[2]}`
        );

        if (response.status) {
          console.log(response?.data);
          setData(response?.data.data);
          setLoad(true);
        }
      } catch (error) {
        console.error("Error retrieving data:", error);
        // window.location.href = '/';
      }
    };

    dataRetriever();
  }, []);
  console.log(data);

  return (
    <div className="mt-24 lg:mt-28 dark:text-white">
      {load ? (
        <div>
          <div className="group/item relative flex justify-center flex-col items-center">
            <div className="flex justify-center items-center group/item cursor-pointer relative p-8">
              <div
                key="1"
                className="rounded-2xl w-32 h-32 lg:w-44 lg:h-44 ring-4 ring-[#656366] ring-offset-4 rotate-45 group-hover/item:rotate-0 group-hover/item:ring-[#037F00] transition delay-150 duration-500 ease-in-out"
              ></div>
              <img
                src={`../${data.img}`}
                className="lg:w-36 lg:h-36 w-24 h-24 absolute mb-6 group-hover/item:mb-0 group-hover/item:grayscale-0 transition-all delay-150 duration-500 ease-in-out"
                alt=""
              />{" "}
            </div>
            <div className="capitalize font-semibold text-center drop-shadow absolute">
              <div class=" lg:group-hover/item:mt-60 group-hover/item:mt-48 lg:mt-72 mt-56 lg:group-hover/item:text-3xl group-hover/item:text-2xl transition-all delay-150 duration-500 ease-in-out text-2xl">
                {data.na}
              </div>
            </div>
          </div>
          <div className="mt-8 flex justify-center items-center font-semibold flex-col">
            <div className="flex flex-row items-center justify-center p-2 lg:w-[60%] text-center">
              <div className="text-md lg:text-lg text-[#7c7c7c] text-center xl:w-[60%] lg:w-[70%] w-[80%]">
                Уг мэргэжилтэй холбоотой мэдээлэл
              </div>
            </div>
            <div className="mt-8 gap-16 flex flex-col md:w-[80%] text-justify lg:text-lg">
              <div className="gap-2 flex flex-col">
                <div className="text-xl	lg:text-3xl text-center">
                  Мэргэжлийн Тодорхойлолт
                </div>
                <div>{data.di}</div>
              </div>
              <div className="gap-2 flex flex-col justify-center items-center">
                <div className="text-xl	lg:text-3xl text-center">
                  Уг мэргэжил дээрх шилдэг их сургуулиуд
                </div>
                <div className="overflow-x-auto h-60 flex flex-row gap-10 w-96 md:w-full snap-x mt-4">
                  {data.sch.map((el) => {
                    return (
                      <div className="w-60 h-60">
                        <div className="flex flex-col justify-center items-center relative w-60 bg-slate-500/10 overflow-hidden h-60 group/item rounded-lg">
                          <img
                            className="w-full h-full p-2 blur-3xl absolute -z-10 transition-all dark:brightness-125 ease-out duration-500 delay-0 transform"
                            src={`https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/sch%2F${el.img}`}
                            alt=""
                          />
                          <img
                            className="group-hover/item:w-24 group-hover/item:h-24 group-hover/item:lg:w-32 group-hover/item:lg:h-32 bg-white object-cover w-full h-full rounded-lg  transition-all ease-out-in duration-500 delay-0 transform"
                            src={`https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/sch%2F${el.img}`}
                            alt=""
                          />

                          <div className="w-[80%] group-hover/item:mt-1 text-center group-hover/item:h-16 h-0 transition-all ease-in-out duration-500 delay-0 transform">
                            {el.n}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="gap-2 flex flex-col justify-center items-center">
                <div className="text-xl	lg:text-3xl text-center">
                  Хийж болох хичээлээс гадуурх үйл ажиллагаа
                </div>
                <div className="flex flex-row overflow-x-auto w-96 md:w-full gap-5 lg:gap-10 text-lg lg:text-xl mt-2 snap-x md:snap-none">
                  {data.tl.map((el) => {
                    return (
                      <div className="snap-end">
                        <div className="flex flex-col items-center group/item gap-2 xl:w-96 lg:w-72 md:w-60 w-52 h-min relative bg-slate-500/10 overflow-hidden rounded-lg">
                          <img
                            src={`https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/Prof%2F${
                              el.n === 1
                                ? "club.png?alt=media&token=8df33618-7120-493f-9856-543c35e65191"
                                : el.n === 2
                                ? "olympiad.png?alt=media&token=99f58327-d3e5-45f0-8d15-cf4f035c00b0"
                                : el.n === 3
                                ? "research.png?alt=media&token=d3f0d319-523d-4f33-8c69-fc1793b30665"
                                : el.n === 4
                                ? "volunteer.png?alt=media&token=76fb194c-9ff9-4274-bb2e-c01a69cdf0d2"
                                : el.n === 5
                                ? "exp.png?alt=media&token=0021900a-df1a-40c7-a857-73fcc17ba40f"
                                : el.n === 6
                                ? "6.png?alt=media&token=3f136521-6174-429e-9b1e-5ceb586355cf"
                                : el.n === 7
                                ? "7.png?alt=media&token=4d778928-8d3c-4687-84f5-02d617295221"
                                : el.n === 8
                                ? "8.png?alt=media&token=5874c908-fd0c-458d-ad62-424aba11858a"
                                : ""
                            }`}
                            className="xl:w-96 xl:h-96 lg:w-72 lg:h-72 md:w-60 md:h-60 w-52 h-52"
                            alt=""
                          />
                          <div className="p-4 pb-10 w-full group-hover/item:opacity-0 text-justify opacity-100 absolute bg-gradient-to-b from-[#000000] to-[#00000000] text-white group-hover/item:blur blur-0 transition-all ease-out duration-500 delay-0 transform">
                            {el.t}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="gap-2 flex flex-col justify-center items-center">
                <div className="text-xl	lg:text-3xl text-center">CHECK LIST</div>
                <div className="flex flex-col gap-4 2xl:gap-10 justify-center text-lg lg:text-xl mt-2 xl:w-[60%]">
                  {data.reqq.map((el) => {
                    return <div className="w-full">- {el}</div>;
                  })}
                </div>
              </div>
              <div className="gap-2 flex flex-col">
                <div className="text-xl	lg:text-3xl text-center">
                  Алдартай хүмүүс
                </div>
                <div className="flex 2xL:h-[600px] xl:h-[500px] lg:h-[400px] h-[300px]">
                  {data.pl.map((el) => {
                    return (
                      <div className="flex flex-row group/item first:rounded-l-lg last:rounded-r-lg overflow-hidden hover:w-[300%] w-full relative transition-all ease-out duration-500 delay-0 transform">
                        <img
                          className=" object-cover group-hover/item:brightness-100 brightness-50  w-full h-full transition-all ease-out duration-500 delay-0 transform"
                          src={`https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/Prof%2F${el.img}`}
                          alt=""
                        />
                        <div className="group-hover/item:opacity-100 opacity-0 absolute w-full bottom-0 p-2 text-white bg-[#00000080] transition-all ease-out duration-300 delay-300 transform">
                          {el.p}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="flex-col w-full flex">
                <div className="text-xl	lg:text-3xl text-center">Бичлэг</div>
                <div className="flex flex-wrap gap-4 justify-center items-center mt-4">
                  {data.vid.map((el) => {
                    let videoId = "";

                    if (Object.values(el)[0].includes("youtu.be")) {
                      videoId = Object.values(el)[0]
                        .split("youtu.be/")[1]
                        .split("?")[0]; // For shortened URLs
                    } else if (Object.values(el)[0].includes("v=")) {
                      videoId = Object.values(el)[0]
                        .split("v=")[1]
                        ?.split("&")[0]; // For regular URLs
                    }

                    const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;

                    return (
                      <div className="relative w-96 rounded-lg overflow-hidden">
                        <a
                          target="_blank"
                          className="hover:text-[#83b160] w-full bottom-0 text-white uppercase absolute underline p-1 lg:p-2 bg-[#0000009b]"
                          rel="noopener noreferrer"
                          href={Object.values(el)[0]}
                        >
                          {Object.keys(el)[0]}
                        </a>
                        <img
                          src={thumbnailUrl}
                          alt="YouTube Thumbnail"
                          className=" object-cover h-auto w-full"
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="flex flex-col items-center justify-center">
                <div className="text-xl	lg:text-3xl text-center">
                  Энэ мэргэжилд илүү нийцдэг MBTI
                </div>
                <div className="flex flex-row text-2xl">
                  {data.mbti.map((el) => {
                    return (
                      <div className="bg-black/10 m-2 p-2 w-20 text-center rounded">
                        {el}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <Comment id={data._id} />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
