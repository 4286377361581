import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import { formatDistanceToNow } from "date-fns";
import { useState, useEffect, useRef } from "react";
// import mhz from '../assets/bid.png';
import gh from "../assets/godhand.jpg";
import nova from "../assets/nova.png";
import erdem from "../assets/erdem.svg";
// import tmll from '../assets/tmll.svg';
// import available from '../assets/bolomjtoi.svg';
// import unavailable from '../assets/bolomjgui.svg';
// import availableb from '../assets/bolomjtoib.svg';
// import unavailableb from '../assets/bolomjguib.svg';
// import sbdbg from '../assets/sbd.png';
// import sat from '../assets/sat.png';
// import ielts from '../assets/ielts.png';
// import topik from '../assets/topik.png';
import { useContext } from "react";
import axios from "axios";
import Comment from "./comment";
export const Article = () => {
  const mergejil = [];
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  const location = useLocation();
  const bairshil = location.pathname;

  const dataRetriever = async () => {
    try {
      console.log("dataRetriever called");
      const response = await axios.get(
        `https://tmull-be.vercel.app/news/${bairshil.split("/")[2]}`
      );
      console.log("API response:", response?.data);
      setData(response?.data?.data);
      setLoad(true);
      document.title = `Tmull ・ ${response?.data?.data?.ti || "Мэдээ"}`;
    } catch (error) {
      console.error("Error retrieving data:", error);
      window.location.href = "/";
    }
  };

  useEffect(() => {
    if (bairshil) {
      dataRetriever();
    }
  }, []);
  const like = async (cId) => {
    if (localStorage.getItem("user")) {
      await axios({
        url: `https://tmull-be.vercel.app/News/like/${cId}`,
        method: "POST",
        data: {
          userId: localStorage.getItem("user"),
        },
      }).then((response) => {
        dataRetriever();
      });
    }
  };
  const dlike = async (cId) => {
    if (localStorage.getItem("user")) {
      await axios({
        url: `https://tmull-be.vercel.app/News/dlike/${cId}`,
        method: "POST",
        data: {
          userId: localStorage.getItem("user"),
        },
      }).then((response) => {
        dataRetriever();
      });
    }
  };
  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Check this out!",
          text: "hello",
          url: "yes",
        });
        console.log("Content shared successfully");
      } catch (error) {
        console.error("Error sharing", error);
      }
    } else {
      alert("Share feature not supported on this browser.");
    }
  };
  return (
    <div className="mt-24 lg:mt-28 dark:text-white">
      {load ? (
        <div className=" font-semibold">
          <div>
            <div className="flex flex-row justify-between items-center">
              <div className="md:text-lg xl:text-xl">
                {formatDistanceToNow(new Date(data.cAt), {
                  addSuffix: true,
                })
                  .replace(
                    "less than a minute ago",
                    "минут хүрэхгүй хугацааны өмнө"
                  )
                  .replace("about ", "")
                  .replace("days", "өдрийн")
                  .replace("day", "өдрийн")
                  .replace("hours", "цагийн")
                  .replace("hour", "цагийн")
                  .replace("minutes", "минутын")
                  .replace("minute", "минутын")
                  .replace("months", "сарын")
                  .replace("month", "сарын")
                  .replace("ago", "өмнө")}{" "}
                {console.log(data)}
              </div>
              <div className="h-full flex flex-row gap-2 lg:gap-4">
                <div className="flex flex-row gap-1 items-center">
                  <div className="flex items-center flex-row justify-center">
                    <svg
                      onClick={() => like(data._id)}
                      style={{
                        fill: data.like.includes(localStorage.getItem("user"))
                          ? "green"
                          : "none",
                      }}
                      className="h-5 md:h-6 xl:h-8 px-2 hover:fill-green-700 cursor-pointer overflow-visible"
                      viewBox="0 0 311 282"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M184.071 113.23L187.117 106.234M184.071 113.23L211.721 41.7504C211.721 26.8784 203.066 12.7324 188.586 6.42538C179.926 2.65638 169.844 6.61939 166.077 15.2844L166.076 15.2874L138.016 79.7244C134.114 88.6984 127.924 96.4704 120.086 102.275C120.089 103.427 120.093 104.937 120.096 106.76C120.103 111.154 120.11 117.377 120.116 124.815C120.129 139.692 120.139 159.434 120.148 179.146C120.156 198.857 120.162 218.538 120.166 233.291C120.167 240.668 120.169 246.813 120.17 251.113V253.858C126.559 255.477 132.687 258.014 138.361 261.396C147.419 266.793 157.767 269.641 168.311 269.641H265.191C279.832 269.641 292.182 258.708 293.957 244.168L293.958 244.165L305.387 150.07L305.387 150.067C307.756 130.474 292.478 113.23 272.751 113.23L184.071 113.23ZM70.2865 275.87L70.2775 275.87L70.2685 275.87L15.1245 276.67C15.1205 276.67 15.1165 276.67 15.1125 276.67C13.7905 276.681 12.4785 276.431 11.2525 275.936C10.0255 275.44 8.90753 274.708 7.96353 273.781C6.06653 271.902 5.00052 269.345 5.00052 266.67V102.649C5.00052 97.1314 9.48252 92.6494 15.0005 92.6494H70.1705C75.6895 92.6494 80.1705 97.1314 80.1705 102.649L80.1705 265.87C80.1705 271.353 75.7635 275.81 70.2865 275.87Z"
                        className="dark:stroke-white stroke-black"
                        stroke-width="20"
                      />
                    </svg>
                    <div>{data.like.length}</div>
                  </div>
                  <div className="flex items-center flex-row justify-center">
                    <svg
                      onClick={() => dlike(data._id)}
                      style={{
                        fill: data.dlike.includes(localStorage.getItem("user"))
                          ? "red"
                          : "none",
                      }}
                      className="h-5 md:h-6 xl:h-8 px-2 hover:fill-red-700 fill-none cursor-pointer overflow-visible"
                      viewBox="0 0 311 282"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M126.56 168.44L123.514 175.436M126.56 168.44L98.9099 239.92C98.9099 254.792 107.565 268.938 122.045 275.245C130.705 279.014 140.787 275.051 144.554 266.386L144.555 266.383L172.615 201.946C176.517 192.972 182.707 185.2 190.545 179.395C190.542 178.243 190.538 176.733 190.535 174.91C190.528 170.516 190.521 164.293 190.515 156.855C190.502 141.978 190.492 122.236 190.483 102.524C190.475 82.813 190.469 63.132 190.465 48.379C190.464 41.002 190.462 34.8578 190.461 30.5574V27.8124C184.072 26.1934 177.944 23.6564 172.27 20.2746C163.212 14.8777 152.864 12.0289 142.32 12.0296H45.4396C30.7982 12.0296 18.4483 22.9621 16.6732 37.5025L16.6728 37.5051L5.24346 131.6L5.24306 131.603C2.87456 151.196 18.1525 168.44 37.8796 168.44H126.56ZM240.344 5.80033L240.353 5.80023L240.362 5.80013L295.506 5.00042C295.51 5.00042 295.514 5.00042 295.518 5.00032C296.84 4.98972 298.152 5.23912 299.378 5.73442C300.605 6.23012 301.723 6.96232 302.667 7.88932C304.564 9.76822 305.63 12.3258 305.63 15.0006V179.021C305.63 184.539 301.148 189.021 295.63 189.021H240.46C234.941 189.021 230.46 184.539 230.46 179.021V15.8006C230.46 10.3177 234.867 5.86033 240.344 5.80033Z"
                        className="dark:stroke-white stroke-black"
                        stroke-width="20"
                      />
                    </svg>
                    <div>{data.dlike.length}</div>
                  </div>
                </div>

                <svg
                  className="lg:h-8 xl:h-10 2xl:h-10 h-6"
                  viewBox="0 0 18 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.5 21.1429L9 15.7857L1.5 21.1429V4.00003C1.5 3.43171 1.72576 2.88667 2.12763 2.48481C2.52949 2.08294 3.07454 1.85718 3.64286 1.85718H14.3571C14.9255 1.85718 15.4705 2.08294 15.8724 2.48481C16.2742 2.88667 16.5 3.43171 16.5 4.00003V21.1429Z"
                    className="stroke-black dark:stroke-white fill-white dark:fill-[#00000000]"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl mt-2">
              {data.ti}
            </div>
            <div className="text-lg lg:text-2xl mt-2 text-[#5c5c5c] dark:text-[#b6b6b6]">
              {data.di}
            </div>
            <div className="flex flex-row justify-between lg:text-lg 2xl:text-xl mt-2 md:mt-4 lg:mt-4 xl:mt-6 2xl:mt-6">
              <div className="gap-1.5 flex flex-row">
                <div>Published by </div>
                {data.au.map((el) => {
                  return (
                    <div className="text-[#037F00] underline decoration-[#037F00]">
                      {el}
                    </div>
                  );
                })}
              </div>
              <div className="flex flex-row items-center gap-2">
                <div className="flex flex-row items-center gap-2 hidden">
                  <div>2</div>
                  <div>
                    <svg
                      className="md:h-6 xl:h-8 h-5"
                      height="auto"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.5 10.25C14.5 10.6478 14.342 11.0294 14.0607 11.3107C13.7794 11.592 13.3978 11.75 13 11.75H4L1 14.75V2.75C1 2.35218 1.15804 1.97064 1.43934 1.68934C1.72064 1.40804 2.10218 1.25 2.5 1.25H13C13.3978 1.25 13.7794 1.40804 14.0607 1.68934C14.342 1.97064 14.5 2.35218 14.5 2.75V10.25Z"
                        className="fill-white stroke-black dark:fill-[#00000000] dark:stroke-white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  onClick={handleShare}
                  className="flex flex-row items-center gap-2 text-base hidden"
                >
                  <div>Хуваалцах</div>
                  <div>
                    <svg
                      className="md:h-6 xl:h-8 h-5"
                      height="auto"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.66675 8V13.3333C2.66675 13.687 2.80722 14.0261 3.05727 14.2761C3.30732 14.5262 3.64646 14.6667 4.00008 14.6667H12.0001C12.3537 14.6667 12.6928 14.5262 12.9429 14.2761C13.1929 14.0261 13.3334 13.687 13.3334 13.3333V8"
                        className="stroke-black dark:stroke-white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.6666 4.00004L7.99992 1.33337L5.33325 4.00004"
                        className="stroke-black dark:stroke-white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8 1.33337V10"
                        className="stroke-black dark:stroke-white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center justify-center">
            <div className="mt-2 w-full relative md:mt-4 lg:mt-4 overflow-hidden xl:mt-6 2xl:mt-6 h-[350px] md:h-[400px] xl:h-[450px] 2xl:h-[500px]">
              <div className="absolute backdrop-blur-sm rounded px-1 m-2 right-0 bg-white/50 dark:bg-black/50">
                {data.rt}
              </div>
              <div className="absolute flex flex-row items-center justify-center gap-1 backdrop-blur-sm rounded px-1 m-2 bg-white/50 dark:bg-black/50">
                <svg
                  className="h-5 dark:fill-white fill-black"
                  viewBox="0 0 512 512"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M256 410.667C306.074 410.667 346.667 370.074 346.667 320.001C346.667 269.927 306.074 229.334 256 229.334C205.926 229.334 165.333 269.927 165.333 320.001C165.333 370.074 205.926 410.667 256 410.667Z" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M480.64 276.693L448.341 244.373C342.101 138.155 169.899 138.155 63.6588 244.373L31.3601 276.693C29.8753 278.178 28.6975 279.941 27.8939 281.881C27.0904 283.821 26.6768 285.9 26.6768 288C26.6768 290.1 27.0904 292.179 27.8939 294.119C28.6975 296.059 29.8753 297.822 31.3601 299.307C32.8449 300.792 34.6077 301.969 36.5477 302.773C38.4877 303.577 40.567 303.99 42.6668 303.99C44.7666 303.99 46.8459 303.577 48.7859 302.773C50.7259 301.969 52.4887 300.792 53.9735 299.307L86.2935 267.008C180.011 173.269 331.989 173.269 425.707 267.008L458.027 299.307C461.026 302.306 465.093 303.99 469.333 303.99C473.574 303.99 477.641 302.306 480.64 299.307C483.639 296.308 485.323 292.241 485.323 288C485.323 283.759 483.639 279.692 480.64 276.693Z"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M402.133 146.496L370.133 201.92C368.208 205.585 367.775 209.853 368.926 213.829C370.078 217.805 372.724 221.182 376.309 223.251C379.895 225.319 384.143 225.92 388.161 224.926C392.18 223.932 395.658 221.422 397.867 217.92L429.867 162.496C431.988 158.818 432.562 154.449 431.462 150.348C430.362 146.247 427.678 142.751 424 140.629C420.322 138.508 415.953 137.934 411.852 139.034C407.751 140.134 404.255 142.818 402.133 146.496ZM240 117.333V181.333C240 185.577 241.686 189.647 244.686 192.647C247.687 195.648 251.757 197.333 256 197.333C260.244 197.333 264.313 195.648 267.314 192.647C270.314 189.647 272 185.577 272 181.333V117.333C272 113.09 270.314 109.02 267.314 106.02C264.313 103.019 260.244 101.333 256 101.333C251.757 101.333 247.687 103.019 244.686 106.02C241.686 109.02 240 113.09 240 117.333ZM82.1334 162.496L114.133 217.92C116.342 221.422 119.82 223.932 123.839 224.926C127.858 225.92 132.105 225.319 135.691 223.251C139.277 221.182 141.922 217.805 143.074 213.829C144.225 209.853 143.793 205.585 141.867 201.92L109.867 146.496C108.816 144.675 107.417 143.079 105.75 141.799C104.082 140.518 102.179 139.579 100.148 139.034C98.1179 138.489 96 138.35 93.9156 138.624C91.8312 138.897 89.8211 139.579 88.0001 140.629C86.1791 141.68 84.5829 143.079 83.3025 144.747C82.0222 146.414 81.0828 148.317 80.538 150.348C79.9933 152.378 79.8538 154.496 80.1275 156.581C80.4012 158.665 81.0829 160.675 82.1334 162.496Z"
                  />
                </svg>
                {data.s}
              </div>
              <img
                src={
                  "https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/News%2F" +
                  data.img
                }
                className="w-full h-full object-cover"
                alt=""
              />
            </div>
          </div>
          {data.ca.length > 0 ? (
            <div className="capitalize flex flex-wrap gap-2">
              Ангилал:{" "}
              <div className="text-green-700">
                {data.ca.map((el) => el).join(", ")}
              </div>
            </div>
          ) : (
            <></>
          )}
          {data.cou.length > 0 ? (
            <div className="capitalize flex flex-wrap gap-2">
              Улс:{" "}
              <div className="text-green-700">
                {data.cou.map((el) => el).join(", ")}
              </div>
            </div>
          ) : (
            <></>
          )}
          {data.ht.length > 0 ? (
            <div className="capitalize flex flex-wrap gap-2">
              Hashtag:{" "}
              <div className="text-green-700">
                {data.ht.map((el) => `#${el}`).join(" ")}
              </div>
            </div>
          ) : (
            <></>
          )}
          <div
            className="flex items-center justify-center mt-16"
            dangerouslySetInnerHTML={{
              __html: data.con
                .replace(/className=/g, "class=")
                .replace(/classname/g, "class"),
            }}
          ></div>
          <Comment id={data._id} />
        </div>
      ) : (
        <div>
          <div className="animate-pulse font-semibold">
            <div>
              <div className="flex flex-row justify-between items-center">
                <div className="md:text-lg xl:text-xl">
                  <div class="h-6 w-32 bg-black/25 dark:bg-white/25 rounded"></div>
                </div>
                <div className="h-full flex flex-row gap-2 lg:gap-4">
                  <div class="h-6 w-6 bg-black/25 dark:bg-white/25 rounded"></div>

                  <div class="h-6 w-6 bg-black/25 dark:bg-white/25 rounded"></div>
                  <div class="h-6 w-6 bg-black/25 dark:bg-white/25 rounded"></div>
                </div>
              </div>
              <div className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl mt-2">
                <div class="h-14 w-full bg-black/25 dark:bg-white/25 rounded"></div>
              </div>
              <div className="flex flex-row justify-between md:text-xl lg:text-xl xl:text-2xl 2xl:text-2xl mt-2 md:mt-4 lg:mt-4 xl:mt-6 2xl:mt-6">
                <div className="gap-1.5 flex flex-row">
                  <div class="h-6 w-64 bg-black/25 dark:bg-white/25 rounded"></div>
                </div>
              </div>
            </div>
            <div className="flex flex-row items-center justify-center">
              <div className="mt-2 w-full relative md:mt-4 lg:mt-4 overflow-hidden xl:mt-6 2xl:mt-6 h-[350px] md:h-[400px] xl:h-[450px] 2xl:h-[500px]">
                <div className="absolute backdrop-blur-sm rounded px-1 m-2 right-0 bg-white/50 dark:bg-black/50 h-4 w-10"></div>
                <div className="absolute flex flex-row items-center justify-center gap-1 backdrop-blur-sm rounded px-1 m-2 bg-white/50 dark:bg-black/50">
                  <svg
                    className="h-5 dark:fill-white fill-black"
                    viewBox="0 0 512 512"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M256 410.667C306.074 410.667 346.667 370.074 346.667 320.001C346.667 269.927 306.074 229.334 256 229.334C205.926 229.334 165.333 269.927 165.333 320.001C165.333 370.074 205.926 410.667 256 410.667Z" />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M480.64 276.693L448.341 244.373C342.101 138.155 169.899 138.155 63.6588 244.373L31.3601 276.693C29.8753 278.178 28.6975 279.941 27.8939 281.881C27.0904 283.821 26.6768 285.9 26.6768 288C26.6768 290.1 27.0904 292.179 27.8939 294.119C28.6975 296.059 29.8753 297.822 31.3601 299.307C32.8449 300.792 34.6077 301.969 36.5477 302.773C38.4877 303.577 40.567 303.99 42.6668 303.99C44.7666 303.99 46.8459 303.577 48.7859 302.773C50.7259 301.969 52.4887 300.792 53.9735 299.307L86.2935 267.008C180.011 173.269 331.989 173.269 425.707 267.008L458.027 299.307C461.026 302.306 465.093 303.99 469.333 303.99C473.574 303.99 477.641 302.306 480.64 299.307C483.639 296.308 485.323 292.241 485.323 288C485.323 283.759 483.639 279.692 480.64 276.693Z"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M402.133 146.496L370.133 201.92C368.208 205.585 367.775 209.853 368.926 213.829C370.078 217.805 372.724 221.182 376.309 223.251C379.895 225.319 384.143 225.92 388.161 224.926C392.18 223.932 395.658 221.422 397.867 217.92L429.867 162.496C431.988 158.818 432.562 154.449 431.462 150.348C430.362 146.247 427.678 142.751 424 140.629C420.322 138.508 415.953 137.934 411.852 139.034C407.751 140.134 404.255 142.818 402.133 146.496ZM240 117.333V181.333C240 185.577 241.686 189.647 244.686 192.647C247.687 195.648 251.757 197.333 256 197.333C260.244 197.333 264.313 195.648 267.314 192.647C270.314 189.647 272 185.577 272 181.333V117.333C272 113.09 270.314 109.02 267.314 106.02C264.313 103.019 260.244 101.333 256 101.333C251.757 101.333 247.687 103.019 244.686 106.02C241.686 109.02 240 113.09 240 117.333ZM82.1334 162.496L114.133 217.92C116.342 221.422 119.82 223.932 123.839 224.926C127.858 225.92 132.105 225.319 135.691 223.251C139.277 221.182 141.922 217.805 143.074 213.829C144.225 209.853 143.793 205.585 141.867 201.92L109.867 146.496C108.816 144.675 107.417 143.079 105.75 141.799C104.082 140.518 102.179 139.579 100.148 139.034C98.1179 138.489 96 138.35 93.9156 138.624C91.8312 138.897 89.8211 139.579 88.0001 140.629C86.1791 141.68 84.5829 143.079 83.3025 144.747C82.0222 146.414 81.0828 148.317 80.538 150.348C79.9933 152.378 79.8538 154.496 80.1275 156.581C80.4012 158.665 81.0829 160.675 82.1334 162.496Z"
                    />
                  </svg>
                  <div className="w-8"></div>
                </div>
                <div className="w-full h-full object-cover bg-black/25 dark:bg-white/25"></div>
              </div>
            </div>
            <div class="h-4 w-[80%] bg-black/25 dark:bg-white/25 rounded mt-2"></div>
            <div className="mt-16 gap-4 flex flex-col">
              <div class="grid grid-cols-3 gap-4 w-full">
                <div class="h-4 bg-black/25 dark:bg-white/25 rounded col-span-2"></div>
                <div class="h-4 bg-black/25 dark:bg-white/25 rounded col-span-1"></div>
              </div>
              <div class="h-4 bg-black/25 dark:bg-white/25 rounded w-full"></div>
              <div class="h-4 bg-black/25 dark:bg-white/25 rounded w-full"></div>
              <div class="h-4 bg-black/25 dark:bg-white/25 rounded w-full"></div>
              <div class="grid grid-cols-3 gap-4 w-full">
                <div class="h-4 bg-black/25 dark:bg-white/25 rounded col-span-1"></div>
                <div class="h-4 bg-black/25 dark:bg-white/25 rounded col-span-2"></div>
              </div>
              <div class="h-4 bg-black/25 dark:bg-white/25 rounded w-full"></div>
              <div class="grid grid-cols-3 gap-4 w-full">
                <div class="h-4 bg-black/25 dark:bg-white/25 rounded col-span-2"></div>
                <div class="h-4 bg-black/25 dark:bg-white/25 rounded col-span-1"></div>
              </div>
              <div class="h-4 bg-black/25 dark:bg-white/25 rounded w-full"></div>
              <div class="h-4 bg-black/25 dark:bg-white/25 rounded w-full"></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
