import React from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { useContext } from "react";
import axios from "axios";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  listAll,
  list,
} from "firebase/storage";
import { storage } from "./firebase";
import { v4 } from "uuid";

export const UploadNews = () => {
  const mergejil = [];
  const [nameV, setNameV] = useState("");
  const [disV, setDisV] = useState("");
  const [locV, setLocV] = useState("");
  const [selectedtype, setSelectedtype] = useState("");
  const [reg, setReg] = useState("");
  const [reg2, setReg2] = useState("");
  const [linkV, setLinkV] = useState("");
  const [dayV, setDayV] = useState("");
  const [day1V, setDay1V] = useState("");
  const [timeV, setTimeV] = useState("");
  const [imgV, setImgV] = useState("");
  const handleRadioChange = (event) => {
    setReg(event.target.value);
  };
  const handleRadioChange2 = (event) => {
    setReg2(event.target.value);
  };
  console.log(reg2);

  const [fields, setFields] = useState([
    { "Зохион байгуулагч": "Tmull" }, // Initial key-value pair
  ]);
  console.log(fields);

  const handleAddField = () => {
    setFields([...fields, { "": "" }]); // Add an empty object for new key-value pair
  };

  const handleRemoveField = (index) => {
    const updatedFields = fields.filter((_, i) => i !== index); // Remove field by index
    setFields(updatedFields);
  };

  const handleKeyChange = (index, newKey) => {
    const updatedFields = fields.map((field, i) => {
      if (i === index) {
        const value = Object.values(field)[0]; // Get the current value
        return { [newKey]: value }; // Update the key with the newKey
      }
      return field;
    });
    setFields(updatedFields);
  };

  const handleValueChange = (index, newValue) => {
    const updatedFields = fields.map((field, i) => {
      if (i === index) {
        const key = Object.keys(field)[0]; // Get the current key
        return { [key]: newValue }; // Update the value
      }
      return field;
    });
    setFields(updatedFields);
  };
  ///
  const [fields1, setFields1] = useState([
    { Instagram: "@tmull" }, // Initial key-value pair
  ]);
  console.log(fields1);

  const handleAddField1 = () => {
    setFields1([...fields1, { "": "" }]); // Add an empty object for new key-value pair
  };

  const handleRemoveField1 = (index) => {
    const updatedFields1 = fields1.filter((_, i) => i !== index); // Remove field by index
    setFields1(updatedFields1);
  };

  const handleKeyChange1 = (index, newKey) => {
    const updatedFields1 = fields1.map((field1, i) => {
      if (i === index) {
        const value = Object.values(field1)[0]; // Get the current value
        return { [newKey]: value }; // Update the key with the newKey
      }
      return field1;
    });
    setFields1(updatedFields1);
  };

  const handleValueChange1 = (index, newValue) => {
    const updatedFields1 = fields1.map((field1, i) => {
      if (i === index) {
        const key = Object.keys(field1)[0]; // Get the current key
        return { [key]: newValue }; // Update the value
      }
      return field1;
    });
    setFields1(updatedFields1);
  };
  ///
  const [fields2, setFields2] = useState([
    { Name: "Тэтгэлэг", type: 1, t: "14:00 - 15:00" },
  ]);

  const handleAddField2 = () => {
    setFields2([...fields2, { Name: "", type: 0, t: "" }]);
  };

  const handleRemoveField2 = (index) => {
    const updatedFields2 = fields2.filter((_, i) => i !== index);
    setFields2(updatedFields2);
  };

  const handleInputChange2 = (index, event) => {
    const { name, value } = event.target;
    const updatedFields2 = fields2.map((field2, i) => {
      if (i === index) {
        return {
          ...field2,
          [name]: name === "type" ? parseInt(value, 10) : value, // Convert type to number
        };
      }
      return field2;
    });
    setFields2(updatedFields2);
  };

  const [previewUrl, setPreviewUrl] = useState(null); // New state for image preview
  const [imageUpload, setImageUpload] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);

  const imagesListRef = ref(storage, "Events/");
  const uploadFile = () => {
    if (imageUpload == null) return;
    const imageRef = ref(storage, `Events/${imageUpload.name}`);
    uploadBytes(imageRef, imageUpload).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        setImgV(url);
        console.log("Uploaded Image URL:", url); // Log the URL
        setImageUrls((prev) => [...prev, url]);
      });
    });
  };

  useEffect(() => {
    listAll(imagesListRef).then((response) => {
      response.items.forEach((item) => {
        getDownloadURL(item).then((url) => {
          setImageUrls((prev) => [...prev, url]);
        });
      });
    });
  }, []);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageUpload(file);
      setPreviewUrl(URL.createObjectURL(file)); // Set the preview URL
    }
  };
  const dataRetriever = async () => {
    if (selectedtype === "none") {
      alert("Төрөл сонгоогүй байна!");
      return;
    } else {
      uploadFile();
      await axios({
        url: `https://tmull-be.vercel.app/event`,
        method: "POST",
        data: {
          n: nameV,
          i: imgV.split("Events%2F")[1],
          nf: disV,
          l: locV,
          r: reg === "y" ? linkV : "n",
          sT: `${dayV}T${timeV}:00.000Z`,
          eT:
            day1V === ""
              ? `${dayV}T${timeV}:00.000Z`
              : `${day1V}T${timeV}:00.000Z`,
          t: selectedtype,
          d: fields,
          c: fields1,
          s: fields2,
        },
      }).then((response) => {
        alert(
          response?.data === "successfully created new Event"
            ? "successfully created new Event"
            : "Error"
        );
        if (response?.data === "successfully created new Event") {
          window.location.href = "/noots/uil_ajillagaa";
        }
      });
    }
  };
  useEffect(() => {
    document.title = "Tmull ・ Upload";
  }, []);
  return (
    <div className="mt-24 lg:mt-28 dark:text-white">
      <div className="w-full text-center text-3xl h-16">
        Event, Program, Volunteer нэмэх талбар
      </div>

      <form>
        <div class="grid gap-6 mb-6 md:grid-cols-2 w-full">
          <div>
            <div
              for="first_name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Нийтлэлийн нэр
            </div>
            <input
              onChange={(e) => setNameV(e.target.value)}
              type="text"
              class="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
              placeholder="Tmull 'Access to Quality Education'"
              required
            />
            <div>
              Анхааруулга: " гэх тэмдэгтийг ашиглахгүй, ' гэх тэмдэгтийг
              ашиглахыг хүсье
            </div>
          </div>
          <div>
            <div
              for="first_name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Нийтлэлийн тайлбар
            </div>
            <input
              onChange={(e) => setDisV(e.target.value)}
              type="text"
              class="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
              placeholder="Энэхүү үйл ажиллагаа маань 9 өөр улсад суралцаж байсан ..."
              required
            />
          </div>
          <div>
            <div
              for="last_name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Унших хугацаа
            </div>
            <input
              type="text"
              onChange={(e) => setLocV(e.target.value)}
              class="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
              placeholder="https://maps.app.goo.gl/..."
              required
            />
          </div>
          <div>
            <div
              for="phone"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Улс
            </div>
            <select
              name="type"
              id="type"
              value={selectedtype}
              required
              onChange={(e) => setSelectedtype(e.target.value)}
              class="bg-gray-50 border-2 border-gray-500 dark:text-white dark:[color-scheme:dark] text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400"
            >
              <option value="none">(сонгох)</option>
              <option value="event">Арга хэмжээ</option>
              <option value="program">Сургалт</option>
              <option value="volunteer">Сайн дурын үйл ажиллагаа</option>
            </select>
          </div>
          <div>
            <div
              for="phone"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Ангилал
            </div>
            <select
              name="type"
              id="type"
              value={selectedtype}
              required
              onChange={(e) => setSelectedtype(e.target.value)}
              class="bg-gray-50 border-2 border-gray-500 dark:text-white dark:[color-scheme:dark] text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400"
            >
              <option value="none">(сонгох)</option>
              <option value="event">Арга хэмжээ</option>
              <option value="program">Сургалт</option>
              <option value="volunteer">Сайн дурын үйл ажиллагаа</option>
            </select>
          </div>
          <div>
            <div
              for="website"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Бүртгэлтэй эсэх
            </div>
            <div className="flex flex-row gap-4 pt-3">
              <div class="flex items-center">
                <input
                  id="default-radio-1"
                  checked={reg === "y"}
                  type="radio"
                  name="default-radio"
                  onChange={handleRadioChange}
                  value="y"
                  className="w-4 h-4 accent-[#037F00] bg-gray-100"
                  required
                />
                <div
                  for="default-radio-1"
                  class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Байгаа
                </div>
              </div>
              <div class="flex items-center">
                <input
                  checked={reg === "n"}
                  id="default-radio-2"
                  type="radio"
                  onChange={handleRadioChange}
                  value="n"
                  name="default-radio"
                  className="w-4 h-4 accent-[#037F00] bg-gray-100"
                  required
                />
                <div
                  for="default-radio-2"
                  class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Байхгүй
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              for="phone"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Hash tag
            </div>
            <input
              type="text"
              onChange={(e) => setLinkV(e.target.value)}
              class="bg-gray-50 disabled:opacity-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
              placeholder="https://forms.gle/..."
              required={reg === "y"}
              disabled={reg === "n"}
            />
          </div>
          <div>
            <div
              for="phone"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Нийтлэлийн холбоо
            </div>
            <input
              type="text"
              onChange={(e) => setLinkV(e.target.value)}
              class="bg-gray-50 disabled:opacity-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
              placeholder="https://forms.gle/..."
              required={reg === "y"}
              disabled={reg === "n"}
            />
          </div>
        </div>

        <div class="max-w-sm">
          <form>
            <div class="block">
              <input
                type="file"
                onChange={handleImageChange}
                class="block w-full text-sm text-gray-500
        file:me-4 file:py-2 file:px-4
        file:rounded-lg file:border-0
        file:text-sm file:font-semibold
        file:bg-green-600 file:text-white
        hover:file:bg-green-700
        file:disabled:opacity-50 file:disabled:pointer-events-none
        dark:text-neutral-500
        dark:file:bg-green-600
        dark:hover:file:bg-green-500
      "
              />
            </div>
          </form>
        </div>
        <p class="text-xs text- text-gray-700 dark:text-gray-300 mt-2">
          SVG, PNG, JPG or GIF (MIN. 1920x1080px)
        </p>
        <div>
          Preview
          {previewUrl && (
            <img
              src={previewUrl}
              alt="Image Preview"
              className="w-full h-72 md:h-96 lg:w-[50%] lg:h-96 object-cover rounded-lg"
            />
          )}
        </div>
        {/* <button
          class="block w-full text-sm text-white
       me-4 py-2 px-4
       rounded-lg border-0 font-semibold
   bg-green-600 
        hover:bg-green-700
        dark:bg-green-600
        dark:hover:bg-green-500
        mt-4
      "
          onClick={uploadFile}
          type="button"
        >
          {" "}
          Upload Image
        </button> */}
        {/* <img src={imgV} alt="" className="w-full lg:h-[80vh] object-cover" /> */}
        {/* <div className="flex flex-wrap gap-2">
        {imageUrls.map((url) => {
          return <img className="w-96 h-60 object-cover" src={url} />;
        })}
      </div> */}
        <button
          class="block w-full text-sm text-white
       me-4 py-2 px-4
       rounded-lg border-0 font-semibold
   bg-green-600 
        hover:bg-green-700
        dark:bg-green-600
        dark:hover:bg-green-500
        mt-4
      "
          type={
            (nameV,
            imgV,
            disV,
            locV,
            reg,
            day1V,
            dayV,
            selectedtype,
            fields,
            fields1,
            fields2) === ""
              ? "submit"
              : "button"
          }
          // type="submit"
          onClick={dataRetriever}
        >
          done
        </button>
      </form>
    </div>
  );
};
