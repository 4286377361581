import React from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import bid from "../assets/we.png";
import gh from "../assets/godhand.jpg";
import bt from "../assets/bt.png";
import ankha from "../assets/ankha.png";
import khulan from "../assets/khulan.jpg";
import anujin from "../assets/anujin.png";
import tursuld from "../assets/tursuld.jpg";
import anar from "../assets/anar.png";
import unicef from "../assets/unicef.png";
import mhz from "../assets/mhz.png";
import gcouncil from "../assets/girl_council.png";
import more from "../assets/more.svg";
// import tmll from '../assets/tmll.svg';
// import available from '../assets/bolomjtoi.svg';
// import unavailable from '../assets/bolomjgui.svg';
// import availableb from '../assets/bolomjtoib.svg';
// import unavailableb from '../assets/bolomjguib.svg';
// import sbdbg from '../assets/sbd.png';
// import sat from '../assets/sat.png';
// import ielts from '../assets/ielts.png';
// import topik from '../assets/topik.png';
import { useContext } from "react";
export const AboutUs = () => {
  const gishuud = [
    {
      nameF: "Bilguuntodrokh Batsukh",
      img: "../assets/bt.png",
      position: "CEO, UI/UX Designer, Project Manager, Full-Stack Developer",
      fb: "https://www.facebook.com/Bilguuntodrokh",
      ig: "https://www.instagram.com/blgntdrkh/",
      ml: "bilguutodrokh@gmail.com",
    },
    {
      nameF: "Anujin Bymbasuren",
      img: "../assets/anujin.png",
      position: "CEO, UI/UX Designer, Project Manager, Full-Stack Developer",
      fb: "https://www.facebook.com/Bilguuntodrokh",
      ig: "https://www.instagram.com/blgntdrkh/",
      ml: "bilguutodrokh@gmail.com",
    },
  ];
  const [n1, setN1] = useState(false);
  const [n2, setN2] = useState(false);
  const [n3, setN3] = useState(false);
  const [n4, setN4] = useState(false);
  const [n5, setN5] = useState(false);
  const [n6, setN6] = useState(false);
  const [qfirst, setQfirst] = useState(false);
  useEffect(() => {
    document.title = "Tmull ・ Бид бол";
  }, []);
  return (
    <div className="mt-24 lg:mt-28 font-semibold dark:text-white">
      <div className="w-full bg-[#98B851] bg-repeat about rounded-3xl  flex flex-col justify-center items-center py-3 sm:py-4 md:py-8 lg:py-12 xl:py-16 2xl:py-20 text-center transition-all delay-150 duration-500 ease-in-out font-semibold">
        <div className="px-2 bg-[#FFFFFF] text-[#037F00] rounded md:rounded-md text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl transition-all delay-150 duration-500 ease-in-out">
          #4 Чанартай боловсролыг дэмжих
        </div>
        <div className="text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl transition-all delay-150 duration-500 ease-in-out">
          <div className="text-[#FFFFFF]">Илүү дээшээ тэмүүлж</div>
          <div className="text-[#037F00]">Ирээдүйгээ бүтээцгээе!</div>
        </div>
        <div className="font-medium text-sm md:text-md lg:text-lg xl:text-xl 2xl:text-2xl text-[#FFFFFF] mt-2 sm:mt-4 md:mt-6 xl:mt-8 transition-all delay-150 duration-500 ease-in-out">
          Боломж бүгдэд
        </div>
        <div className="w-[90%] sm:w-[85%] md:w-[80%] lg:w-[75%] xl:w-[70%] 2xl:w-[65%] text-xs sm:text-sm md:text-md xl:text-lg text-[#FFFFFF] text-justify mt-2 sm:mt-4 md:mt-6 xl:mt-8 font-medium transition-all delay-150 duration-500 ease-in-out">
          “Tmull” веб хуудасны гол зорилго нь тогтвортой хөгжлийн 4, 8, 9, 10,
          17-р зорилт болох “Чанартай боловсролыг дэмжих”, “Эдийн засгийн өсөлт,
          баталгаат ажлын байрыг бий болгох”, “Инноваци болон дэд бүтцийн
          нэмэгдүүлэх”, “Тэгш бус байдлыг бууруулах”, “Хөгжлийн төлөөх
          түншлэлийг бэхжүүлэх“ гэх 5-н зорилтуудыг хэрэгжүүлэн их дээд
          сургуулиудад сурах хүсэл тэмүүлэлтэй болон ирээдүйн мэргэжлээ
          гүнзгийрүүлэн судлах хүсэлтэй сурагч, оюутан, залуусыг хэрэгтэй
          мэдлэг, мэдээллээр хангаж, туслахад оршино. Үүгээр дамжуулан
          боловсролын тэгш бус байдлыг бууруулах, мэдээллийн хүртээмжийг
          нэмэгдүүлэх тухай олон нийтэд нөлөөлөх зорилготой.
        </div>
      </div>
      <div className="flex flex-col items-center justify-center xl:space-x-4 xl:flex-row mt-8 lg:mt-12 xl:mt-16 2xl:mt-20 transition-all ease-in-out duration-300 delay-0 transform">
        <div className="">
          <img
            src={bid}
            className="w-full grayscale hover:grayscale-0 ransition-all delay-0 duration-1000 ease-in-out cursor-pointer"
            alt=""
          />
        </div>
        <div className="xl:w-5/12">
          <div className="font-semibold text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl transition-all ease-in-out duration-300 delay-0 transform">
            Товч танилцуулга
          </div>
          <div className="text-sm md:text-md lg:text-lg xl:text-xl transition-all ease-in-out duration-300 delay-0 transform">
            Бид UNICEF болон МХЗ-тэй хамтарч буй “Tmull” вебсайтын хамт олон
            байна. Бидний гол зорилго нь их дээд сургуулиудад сурах хүсэл
            тэмүүлэлтэй болон ирээдүйн мэргэжлээ гүнзгийрүүлэн судлах хүсэлтэй
            сурагч, оюутан, залуусыг хэрэгтэй мэдлэг, мэдээллээр хангаж,
            туслахад оршино.
          </div>
        </div>
      </div>
      <div className="mt-8 lg:mt-12 xl:mt-16 2xl:mt-20 flex justify-center flex-col items-center">
        <div className="text-[#4BAB4D]">Манай Баг</div>
        <div className="text-2xl xl:text-3xl 2xl:text-4xl w-full text-center">
          Чадварлаг хүүхэд залуус дүүрэн
        </div>
        <div class="relative flex gap-6 snap-x snap-mandatory overflow-x-auto mt-4">
          <div class="snap-start lg:hidden scroll-mx-6 shrink-0">
            <div class="shrink-0 w-0"></div>
          </div>

          <div class="snap-start scroll-mx-6 shrink-0 flex justify-center">
            <img
              class="w-60 md:w-64 lg:w-72 xl:w-80 h-auto rounded-lg shadow-xl bg-white object-cover drop-shadow-lg border border-neutral-300 dark:border-neutral-700"
              alt="Bilguuntodrokh"
              src={bt}
            />
            <div className="absolute bottom-3 bg-[#40404040] text-white backdrop-blur w-56 md:w-60 lg:w-64 xl:w-72 rounded-lg flex flex-col justify-between items-center px-2 lg:px-4 py-1 lg:py-2 drop-shadow-lg gap-1">
              <div
                onClick={() => {
                  setN1(!n1);
                }}
                className="flex flex-row justify-between items-center w-full cursor-pointer"
              >
                <div className="drop-shadow-xl">
                  <div className="text-base lg:text-md xl:text-lg">
                    Bilguuntodrokh Batsukh{" "}
                  </div>
                  <div className="text-xs xl:text-sm text-[#ffffffd4]">
                    CEO, UI/UX Designer, Project Manager, Full-Stack Developer
                  </div>
                </div>
                <div>
                  <img
                    src={more}
                    className={
                      n1
                        ? "h-4 drop-shadow-lg opacity-75 transition-all ease-in duration-500 delay-50"
                        : "h-4 rotate-180 drop-shadow-lg opacity-75 transition-all ease-in duration-500 delay-50"
                    }
                    alt=""
                  />
                </div>
              </div>
              <div className="w-full text-sm text-center text-white transition-all ease-in duration-150 delay-0">
                <div
                  className={
                    n1
                      ? "h-14 flex flex-row w-full justify-between opacity-100 transition-all ease-in-out duration-700 delay-0"
                      : "flex flex-row w-full justify-between h-0 opacity-0 transition-all ease-in-out duration-700 delay-0"
                  }
                >
                  <a
                    href="https://www.facebook.com/Bilguuntodrokh"
                    className="flex flex-col-reverse justify-center group/item"
                  >
                    <div className="cursor-pointer group-hover/item:opacity-100 opacity-75 transition-all ease-in duration-150 delay-0">
                      Facebook
                    </div>
                    <svg
                      className="h-10 transition-all items-center ease-in duration-150 delay-0 cursor-pointer"
                      viewBox="0 0 35 35"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className="group-hover/item:fill-white fill-[#ffffffd4] transition-all ease-in duration-300 delay-0"
                        d="M17.5001 0C7.83516 0 0 7.86792 0 17.5733C0 26.2778 6.30913 33.4867 14.5814 34.8826V21.2395H10.3599V16.3299H14.5814V12.7098C14.5814 8.5094 17.1362 6.22042 20.8682 6.22042C22.6556 6.22042 24.1917 6.35416 24.6376 6.41307V10.8036L22.0492 10.8049C20.02 10.8049 19.6288 11.773 19.6288 13.194V16.3274H24.4706L23.8391 21.237H19.6288V35C28.2873 33.9418 35 26.5493 35 17.5682C35 7.86792 27.1648 0 17.5001 0Z"
                      />
                    </svg>
                  </a>
                  <a
                    href="https://www.instagram.com/blgntdrkh/"
                    className="flex flex-col-reverse justify-center group/item"
                  >
                    <div className="cursor-pointer group-hover/item:opacity-100 opacity-75  transition-all ease-in duration-150 delay-0">
                      Instagram
                    </div>
                    <svg
                      className="h-10 transition-all items-center ease-in duration-150 delay-0 cursor-pointer"
                      viewBox="0 0 35 35"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_1254_2470"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="35"
                        height="35"
                      >
                        <circle cx="17.5" cy="17.5" r="17.5" fill="white" />
                      </mask>
                      <g mask="url(#mask0_1254_2470)">
                        <path
                          className="group-hover/item:fill-white fill-[#ffffffd4] transition-all ease-in duration-150 delay-0"
                          d="M20.5762 17.5C20.5762 19.1989 19.1989 20.5762 17.5 20.5762C15.8011 20.5762 14.4238 19.1989 14.4238 17.5C14.4238 15.8011 15.8011 14.4238 17.5 14.4238C19.1989 14.4238 20.5762 15.8011 20.5762 17.5Z"
                          fill="black"
                        />
                        <path
                          className="group-hover/item:fill-white fill-[#ffffffd4] transition-all ease-in duration-300 delay-0"
                          d="M22.6953 8.20312H12.3047C10.0429 8.20312 8.20312 10.0429 8.20312 12.3047V22.6953C8.20312 24.9571 10.0429 26.7969 12.3047 26.7969H22.6953C24.9571 26.7969 26.7969 24.9571 26.7969 22.6953V12.3047C26.7969 10.0429 24.9571 8.20312 22.6953 8.20312ZM17.5 22.627C14.673 22.627 12.373 20.327 12.373 17.5C12.373 14.673 14.673 12.373 17.5 12.373C20.327 12.373 22.627 14.673 22.627 17.5C22.627 20.327 20.327 22.627 17.5 22.627ZM23.3789 12.6465C22.8125 12.6465 22.3535 12.1875 22.3535 11.6211C22.3535 11.0547 22.8125 10.5957 23.3789 10.5957C23.9453 10.5957 24.4043 11.0547 24.4043 11.6211C24.4043 12.1875 23.9453 12.6465 23.3789 12.6465Z"
                          fill="black"
                        />
                        <path
                          className="group-hover/item:fill-white fill-[#ffffffd4] transition-all ease-in duration-500 delay-0"
                          d="M25.7715 0H9.22852C4.13998 0 0 4.14005 0 9.22852V25.7715C0 30.86 4.14005 35 9.22852 35H25.7715C30.86 35 35 30.86 35 25.7715V9.22852C35 4.13998 30.86 0 25.7715 0ZM28.8477 22.6953C28.8477 26.0876 26.0876 28.8477 22.6953 28.8477H12.3047C8.91235 28.8477 6.15234 26.0876 6.15234 22.6953V12.3047C6.15234 8.91235 8.91235 6.15234 12.3047 6.15234H22.6953C26.0876 6.15234 28.8477 8.91235 28.8477 12.3047V22.6953Z"
                          fill="black"
                        />
                      </g>
                    </svg>
                  </a>
                  <a
                    href="mailto:bilguutodrokh@gmail.com"
                    className="flex flex-col-reverse justify-center group/item"
                  >
                    <div className="cursor-pointer group-hover/item:opacity-100 opacity-75 transition-all ease-in duration-150 delay-0">
                      E-Mail
                    </div>
                    <svg
                      className="h-10 transition-all items-center ease-in duration-150 delay-0 cursor-pointer"
                      viewBox="0 0 512 512"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_1569_2854)">
                        <path
                          className="group-hover/item:fill-white fill-[#ffffffd4] transition-all ease-in duration-150 delay-0"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M256 512C114.841 512 0 397.159 0 256C0 114.841 114.841 0 256 0C397.159 0 512 114.841 512 256C512 397.159 397.159 512 256 512ZM420.966 171.794V340.208C420.966 357.967 406.517 372.416 388.758 372.416H123.241C105.481 372.416 91.034 357.967 91.034 340.208V171.794C91.034 154.035 105.482 139.587 123.241 139.587H388.758C406.517 139.586 420.966 154.035 420.966 171.794Z"
                        />
                        <path
                          className="group-hover/item:fill-white fill-[#ffffffd4] transition-all ease-in duration-300 delay-0"
                          d="M392.804 344.251C391.731 345.325 390.275 345.928 388.758 345.93H123.241C121.723 345.928 120.268 345.325 119.194 344.251C118.121 343.178 117.517 341.723 117.516 340.205V179.488L247.765 282.92C250.176 284.834 253.088 285.792 255.999 285.792C258.91 285.792 261.823 284.835 264.233 282.92L394.483 179.488V340.205C394.481 341.723 393.877 343.178 392.804 344.251Z"
                        />
                        <path
                          className="group-hover/item:fill-white fill-[#ffffffd4] transition-all ease-in duration-500 delay-0"
                          d="M255.999 255.643L368.797 166.068H143.2L255.999 255.643Z"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1569_2854">
                          <rect width="512" height="512" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="snap-start scroll-mx-6 shrink-0 flex justify-center">
            <img
              class="w-60 md:w-64 lg:w-72 xl:w-80 h-auto rounded-lg shadow-xl bg-white object-cover drop-shadow-lg border border-neutral-300 dark:border-neutral-700"
              alt="Anujin"
              src={anujin}
            />
            <div className="absolute bottom-3 bg-[#40404040] text-white backdrop-blur w-56 md:w-60 lg:w-64 xl:w-72 rounded-lg flex flex-col justify-between items-center px-2 lg:px-4 py-1 lg:py-2 drop-shadow-lg gap-1">
              <div
                onClick={() => {
                  setN2(!n2);
                }}
                className="flex flex-row justify-between items-center w-full cursor-pointer"
              >
                <div className="drop-shadow-xl">
                  <div className="text-base lg:text-md xl:text-lg">
                    Anujin Bymbasuren
                  </div>
                  <div className="text-xs xl:text-sm text-[#ffffffd4]">
                    Researcher, Executive assistants, Event Manager
                  </div>
                </div>
                <div>
                  <img
                    src={more}
                    className={
                      n2
                        ? "h-4 drop-shadow-lg opacity-75 transition-all ease-in duration-500 delay-50"
                        : "h-4 rotate-180 drop-shadow-lg opacity-75 transition-all ease-in duration-500 delay-50"
                    }
                    alt=""
                  />
                </div>
              </div>
              <div className="w-full text-sm text-center text-white transition-all ease-in duration-150 delay-0">
                <div
                  className={
                    n2
                      ? "h-14 flex flex-row w-full justify-between opacity-100 transition-all ease-in-out duration-700 delay-0"
                      : "flex flex-row w-full justify-between h-0 opacity-0 transition-all ease-in-out duration-700 delay-0"
                  }
                >
                  <a
                    href="https://www.facebook.com/byambasuren.anujin.3"
                    className="flex flex-col-reverse justify-center group/item"
                  >
                    <div className="cursor-pointer group-hover/item:opacity-100 opacity-75 transition-all ease-in duration-150 delay-0">
                      Facebook
                    </div>
                    <svg
                      className="h-10 transition-all items-center ease-in duration-150 delay-0 cursor-pointer"
                      viewBox="0 0 35 35"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className="group-hover/item:fill-white fill-[#ffffffd4] transition-all ease-in duration-300 delay-0"
                        d="M17.5001 0C7.83516 0 0 7.86792 0 17.5733C0 26.2778 6.30913 33.4867 14.5814 34.8826V21.2395H10.3599V16.3299H14.5814V12.7098C14.5814 8.5094 17.1362 6.22042 20.8682 6.22042C22.6556 6.22042 24.1917 6.35416 24.6376 6.41307V10.8036L22.0492 10.8049C20.02 10.8049 19.6288 11.773 19.6288 13.194V16.3274H24.4706L23.8391 21.237H19.6288V35C28.2873 33.9418 35 26.5493 35 17.5682C35 7.86792 27.1648 0 17.5001 0Z"
                      />
                    </svg>
                  </a>
                  <a
                    href="https://www.instagram.com/b_anujin_/"
                    className="flex flex-col-reverse justify-center group/item"
                  >
                    <div className="cursor-pointer group-hover/item:opacity-100 opacity-75  transition-all ease-in duration-150 delay-0">
                      Instagram
                    </div>
                    <svg
                      className="h-10 transition-all items-center ease-in duration-150 delay-0 cursor-pointer"
                      viewBox="0 0 35 35"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_1254_2470"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="35"
                        height="35"
                      >
                        <circle cx="17.5" cy="17.5" r="17.5" fill="white" />
                      </mask>
                      <g mask="url(#mask0_1254_2470)">
                        <path
                          className="group-hover/item:fill-white fill-[#ffffffd4] transition-all ease-in duration-150 delay-0"
                          d="M20.5762 17.5C20.5762 19.1989 19.1989 20.5762 17.5 20.5762C15.8011 20.5762 14.4238 19.1989 14.4238 17.5C14.4238 15.8011 15.8011 14.4238 17.5 14.4238C19.1989 14.4238 20.5762 15.8011 20.5762 17.5Z"
                          fill="black"
                        />
                        <path
                          className="group-hover/item:fill-white fill-[#ffffffd4] transition-all ease-in duration-300 delay-0"
                          d="M22.6953 8.20312H12.3047C10.0429 8.20312 8.20312 10.0429 8.20312 12.3047V22.6953C8.20312 24.9571 10.0429 26.7969 12.3047 26.7969H22.6953C24.9571 26.7969 26.7969 24.9571 26.7969 22.6953V12.3047C26.7969 10.0429 24.9571 8.20312 22.6953 8.20312ZM17.5 22.627C14.673 22.627 12.373 20.327 12.373 17.5C12.373 14.673 14.673 12.373 17.5 12.373C20.327 12.373 22.627 14.673 22.627 17.5C22.627 20.327 20.327 22.627 17.5 22.627ZM23.3789 12.6465C22.8125 12.6465 22.3535 12.1875 22.3535 11.6211C22.3535 11.0547 22.8125 10.5957 23.3789 10.5957C23.9453 10.5957 24.4043 11.0547 24.4043 11.6211C24.4043 12.1875 23.9453 12.6465 23.3789 12.6465Z"
                          fill="black"
                        />
                        <path
                          className="group-hover/item:fill-white fill-[#ffffffd4] transition-all ease-in duration-500 delay-0"
                          d="M25.7715 0H9.22852C4.13998 0 0 4.14005 0 9.22852V25.7715C0 30.86 4.14005 35 9.22852 35H25.7715C30.86 35 35 30.86 35 25.7715V9.22852C35 4.13998 30.86 0 25.7715 0ZM28.8477 22.6953C28.8477 26.0876 26.0876 28.8477 22.6953 28.8477H12.3047C8.91235 28.8477 6.15234 26.0876 6.15234 22.6953V12.3047C6.15234 8.91235 8.91235 6.15234 12.3047 6.15234H22.6953C26.0876 6.15234 28.8477 8.91235 28.8477 12.3047V22.6953Z"
                          fill="black"
                        />
                      </g>
                    </svg>
                  </a>
                  <a
                    href="mailto:anujin.1123.1123@gmail.com"
                    className="flex flex-col-reverse justify-center group/item"
                  >
                    <div className="cursor-pointer group-hover/item:opacity-100 opacity-75 transition-all ease-in duration-150 delay-0">
                      E-Mail
                    </div>
                    <svg
                      className="h-10 transition-all items-center ease-in duration-150 delay-0 cursor-pointer"
                      viewBox="0 0 512 512"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_1569_2854)">
                        <path
                          className="group-hover/item:fill-white fill-[#ffffffd4] transition-all ease-in duration-150 delay-0"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M256 512C114.841 512 0 397.159 0 256C0 114.841 114.841 0 256 0C397.159 0 512 114.841 512 256C512 397.159 397.159 512 256 512ZM420.966 171.794V340.208C420.966 357.967 406.517 372.416 388.758 372.416H123.241C105.481 372.416 91.034 357.967 91.034 340.208V171.794C91.034 154.035 105.482 139.587 123.241 139.587H388.758C406.517 139.586 420.966 154.035 420.966 171.794Z"
                        />
                        <path
                          className="group-hover/item:fill-white fill-[#ffffffd4] transition-all ease-in duration-300 delay-0"
                          d="M392.804 344.251C391.731 345.325 390.275 345.928 388.758 345.93H123.241C121.723 345.928 120.268 345.325 119.194 344.251C118.121 343.178 117.517 341.723 117.516 340.205V179.488L247.765 282.92C250.176 284.834 253.088 285.792 255.999 285.792C258.91 285.792 261.823 284.835 264.233 282.92L394.483 179.488V340.205C394.481 341.723 393.877 343.178 392.804 344.251Z"
                        />
                        <path
                          className="group-hover/item:fill-white fill-[#ffffffd4] transition-all ease-in duration-500 delay-0"
                          d="M255.999 255.643L368.797 166.068H143.2L255.999 255.643Z"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1569_2854">
                          <rect width="512" height="512" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="snap-start scroll-mx-6 shrink-0 flex justify-center">
            <img
              class="w-60 md:w-64 lg:w-72 xl:w-80 h-auto rounded-lg shadow-xl bg-white object-cover drop-shadow-lg border border-neutral-300 dark:border-neutral-700"
              alt="Bilguuntodrokh"
              src={tursuld}
            />
            <div className="absolute bottom-3 bg-[#40404040] text-white backdrop-blur w-56 md:w-60 lg:w-64 xl:w-72 rounded-lg flex flex-col justify-between items-center px-2 lg:px-4 py-1 lg:py-2 drop-shadow-lg gap-1">
              <div
                onClick={() => {
                  setN3(!n3);
                }}
                className="flex flex-row justify-between items-center w-full cursor-pointer"
              >
                <div className="drop-shadow-xl">
                  <div className="text-base lg:text-md xl:text-lg">
                    Tursuld Nyamdash
                  </div>
                  <div className="text-xs xl:text-sm text-[#ffffffd4]">
                    Field Researcher, Statistician, Event organizer
                  </div>
                </div>
                <div>
                  <img
                    src={more}
                    className={
                      n3
                        ? "h-4 drop-shadow-lg opacity-75 transition-all ease-in duration-500 delay-50"
                        : "h-4 rotate-180 drop-shadow-lg opacity-75 transition-all ease-in duration-500 delay-50"
                    }
                    alt=""
                  />
                </div>
              </div>
              <div className="w-full text-sm text-center text-white transition-all ease-in duration-150 delay-0">
                <div
                  className={
                    n3
                      ? "h-14 flex flex-row w-full justify-between opacity-100 transition-all ease-in-out duration-700 delay-0"
                      : "flex flex-row w-full justify-between h-0 opacity-0 transition-all ease-in-out duration-700 delay-0"
                  }
                >
                  <a
                    href="https://www.facebook.com/tursuld.tursuld.3"
                    className="flex flex-col-reverse justify-center group/item"
                  >
                    <div className="cursor-pointer group-hover/item:opacity-100 opacity-75 transition-all ease-in duration-150 delay-0">
                      Facebook
                    </div>
                    <svg
                      className="h-10 transition-all items-center ease-in duration-150 delay-0 cursor-pointer"
                      viewBox="0 0 35 35"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className="group-hover/item:fill-white fill-[#ffffffd4] transition-all ease-in duration-300 delay-0"
                        d="M17.5001 0C7.83516 0 0 7.86792 0 17.5733C0 26.2778 6.30913 33.4867 14.5814 34.8826V21.2395H10.3599V16.3299H14.5814V12.7098C14.5814 8.5094 17.1362 6.22042 20.8682 6.22042C22.6556 6.22042 24.1917 6.35416 24.6376 6.41307V10.8036L22.0492 10.8049C20.02 10.8049 19.6288 11.773 19.6288 13.194V16.3274H24.4706L23.8391 21.237H19.6288V35C28.2873 33.9418 35 26.5493 35 17.5682C35 7.86792 27.1648 0 17.5001 0Z"
                      />
                    </svg>
                  </a>
                  <a
                    href="https://www.instagram.com/ryo_turuuu/"
                    className="flex flex-col-reverse justify-center group/item"
                  >
                    <div className="cursor-pointer group-hover/item:opacity-100 opacity-75  transition-all ease-in duration-150 delay-0">
                      Instagram
                    </div>
                    <svg
                      className="h-10 transition-all items-center ease-in duration-150 delay-0 cursor-pointer"
                      viewBox="0 0 35 35"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_1254_2470"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="35"
                        height="35"
                      >
                        <circle cx="17.5" cy="17.5" r="17.5" fill="white" />
                      </mask>
                      <g mask="url(#mask0_1254_2470)">
                        <path
                          className="group-hover/item:fill-white fill-[#ffffffd4] transition-all ease-in duration-150 delay-0"
                          d="M20.5762 17.5C20.5762 19.1989 19.1989 20.5762 17.5 20.5762C15.8011 20.5762 14.4238 19.1989 14.4238 17.5C14.4238 15.8011 15.8011 14.4238 17.5 14.4238C19.1989 14.4238 20.5762 15.8011 20.5762 17.5Z"
                          fill="black"
                        />
                        <path
                          className="group-hover/item:fill-white fill-[#ffffffd4] transition-all ease-in duration-300 delay-0"
                          d="M22.6953 8.20312H12.3047C10.0429 8.20312 8.20312 10.0429 8.20312 12.3047V22.6953C8.20312 24.9571 10.0429 26.7969 12.3047 26.7969H22.6953C24.9571 26.7969 26.7969 24.9571 26.7969 22.6953V12.3047C26.7969 10.0429 24.9571 8.20312 22.6953 8.20312ZM17.5 22.627C14.673 22.627 12.373 20.327 12.373 17.5C12.373 14.673 14.673 12.373 17.5 12.373C20.327 12.373 22.627 14.673 22.627 17.5C22.627 20.327 20.327 22.627 17.5 22.627ZM23.3789 12.6465C22.8125 12.6465 22.3535 12.1875 22.3535 11.6211C22.3535 11.0547 22.8125 10.5957 23.3789 10.5957C23.9453 10.5957 24.4043 11.0547 24.4043 11.6211C24.4043 12.1875 23.9453 12.6465 23.3789 12.6465Z"
                          fill="black"
                        />
                        <path
                          className="group-hover/item:fill-white fill-[#ffffffd4] transition-all ease-in duration-500 delay-0"
                          d="M25.7715 0H9.22852C4.13998 0 0 4.14005 0 9.22852V25.7715C0 30.86 4.14005 35 9.22852 35H25.7715C30.86 35 35 30.86 35 25.7715V9.22852C35 4.13998 30.86 0 25.7715 0ZM28.8477 22.6953C28.8477 26.0876 26.0876 28.8477 22.6953 28.8477H12.3047C8.91235 28.8477 6.15234 26.0876 6.15234 22.6953V12.3047C6.15234 8.91235 8.91235 6.15234 12.3047 6.15234H22.6953C26.0876 6.15234 28.8477 8.91235 28.8477 12.3047V22.6953Z"
                          fill="black"
                        />
                      </g>
                    </svg>
                  </a>
                  <a
                    href="mailto:tursuld11@gmail.com"
                    className="flex flex-col-reverse justify-center group/item"
                  >
                    <div className="cursor-pointer group-hover/item:opacity-100 opacity-75 transition-all ease-in duration-150 delay-0">
                      E-Mail
                    </div>
                    <svg
                      className="h-10 transition-all items-center ease-in duration-150 delay-0 cursor-pointer"
                      viewBox="0 0 512 512"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_1569_2854)">
                        <path
                          className="group-hover/item:fill-white fill-[#ffffffd4] transition-all ease-in duration-150 delay-0"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M256 512C114.841 512 0 397.159 0 256C0 114.841 114.841 0 256 0C397.159 0 512 114.841 512 256C512 397.159 397.159 512 256 512ZM420.966 171.794V340.208C420.966 357.967 406.517 372.416 388.758 372.416H123.241C105.481 372.416 91.034 357.967 91.034 340.208V171.794C91.034 154.035 105.482 139.587 123.241 139.587H388.758C406.517 139.586 420.966 154.035 420.966 171.794Z"
                        />
                        <path
                          className="group-hover/item:fill-white fill-[#ffffffd4] transition-all ease-in duration-300 delay-0"
                          d="M392.804 344.251C391.731 345.325 390.275 345.928 388.758 345.93H123.241C121.723 345.928 120.268 345.325 119.194 344.251C118.121 343.178 117.517 341.723 117.516 340.205V179.488L247.765 282.92C250.176 284.834 253.088 285.792 255.999 285.792C258.91 285.792 261.823 284.835 264.233 282.92L394.483 179.488V340.205C394.481 341.723 393.877 343.178 392.804 344.251Z"
                        />
                        <path
                          className="group-hover/item:fill-white fill-[#ffffffd4] transition-all ease-in duration-500 delay-0"
                          d="M255.999 255.643L368.797 166.068H143.2L255.999 255.643Z"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1569_2854">
                          <rect width="512" height="512" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="snap-start scroll-mx-6 shrink-0 flex justify-center w-60 md:w-64 lg:w-72 xl:w-80">
            <img
              class="w-full h-full rounded-lg shadow-xl bg-white object-cover drop-shadow-lg border border-neutral-300 dark:border-neutral-700"
              alt="Anar"
              src={anar}
            />
            <div className="absolute bottom-3 bg-[#40404040] text-white backdrop-blur w-56 md:w-60 lg:w-64 xl:w-72 rounded-lg flex flex-col justify-between items-center px-2 lg:px-4 py-1 lg:py-2 drop-shadow-lg gap-1">
              <div
                onClick={() => {
                  setN4(!n4);
                }}
                className="flex flex-row justify-between items-center w-full cursor-pointer"
              >
                <div className="drop-shadow-xl">
                  <div className="text-base lg:text-md xl:text-lg">Anar Ma</div>
                  <div className="text-xs xl:text-sm text-[#ffffffd4]">
                    Social Media Manager, Marketing Manager
                  </div>
                </div>
                <div>
                  <img
                    src={more}
                    className={
                      n4
                        ? "h-4 drop-shadow-lg opacity-75 transition-all ease-in duration-500 delay-50"
                        : "h-4 rotate-180 drop-shadow-lg opacity-75 transition-all ease-in duration-500 delay-50"
                    }
                    alt=""
                  />
                </div>
              </div>
              <div className="w-full text-sm text-center text-white transition-all ease-in duration-150 delay-0">
                <div
                  className={
                    n4
                      ? "h-14 flex flex-row w-full justify-between opacity-100 transition-all ease-in-out duration-700 delay-0"
                      : "flex flex-row w-full justify-between h-0 opacity-0 transition-all ease-in-out duration-700 delay-0"
                  }
                >
                  <a
                    href="https://www.facebook.com/profile.php?id=100091968740748"
                    className="flex flex-col-reverse justify-center group/item"
                  >
                    <div className="cursor-pointer group-hover/item:opacity-100 opacity-75 transition-all ease-in duration-150 delay-0">
                      Facebook
                    </div>
                    <svg
                      className="h-10 transition-all items-center ease-in duration-150 delay-0 cursor-pointer"
                      viewBox="0 0 35 35"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className="group-hover/item:fill-white fill-[#ffffffd4] transition-all ease-in duration-300 delay-0"
                        d="M17.5001 0C7.83516 0 0 7.86792 0 17.5733C0 26.2778 6.30913 33.4867 14.5814 34.8826V21.2395H10.3599V16.3299H14.5814V12.7098C14.5814 8.5094 17.1362 6.22042 20.8682 6.22042C22.6556 6.22042 24.1917 6.35416 24.6376 6.41307V10.8036L22.0492 10.8049C20.02 10.8049 19.6288 11.773 19.6288 13.194V16.3274H24.4706L23.8391 21.237H19.6288V35C28.2873 33.9418 35 26.5493 35 17.5682C35 7.86792 27.1648 0 17.5001 0Z"
                      />
                    </svg>
                  </a>
                  <a
                    href="https://www.instagram.com/anar__rana/"
                    className="flex flex-col-reverse justify-center group/item"
                  >
                    <div className="cursor-pointer group-hover/item:opacity-100 opacity-75  transition-all ease-in duration-150 delay-0">
                      Instagram
                    </div>
                    <svg
                      className="h-10 transition-all items-center ease-in duration-150 delay-0 cursor-pointer"
                      viewBox="0 0 35 35"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_1254_2470"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="35"
                        height="35"
                      >
                        <circle cx="17.5" cy="17.5" r="17.5" fill="white" />
                      </mask>
                      <g mask="url(#mask0_1254_2470)">
                        <path
                          className="group-hover/item:fill-white fill-[#ffffffd4] transition-all ease-in duration-150 delay-0"
                          d="M20.5762 17.5C20.5762 19.1989 19.1989 20.5762 17.5 20.5762C15.8011 20.5762 14.4238 19.1989 14.4238 17.5C14.4238 15.8011 15.8011 14.4238 17.5 14.4238C19.1989 14.4238 20.5762 15.8011 20.5762 17.5Z"
                          fill="black"
                        />
                        <path
                          className="group-hover/item:fill-white fill-[#ffffffd4] transition-all ease-in duration-300 delay-0"
                          d="M22.6953 8.20312H12.3047C10.0429 8.20312 8.20312 10.0429 8.20312 12.3047V22.6953C8.20312 24.9571 10.0429 26.7969 12.3047 26.7969H22.6953C24.9571 26.7969 26.7969 24.9571 26.7969 22.6953V12.3047C26.7969 10.0429 24.9571 8.20312 22.6953 8.20312ZM17.5 22.627C14.673 22.627 12.373 20.327 12.373 17.5C12.373 14.673 14.673 12.373 17.5 12.373C20.327 12.373 22.627 14.673 22.627 17.5C22.627 20.327 20.327 22.627 17.5 22.627ZM23.3789 12.6465C22.8125 12.6465 22.3535 12.1875 22.3535 11.6211C22.3535 11.0547 22.8125 10.5957 23.3789 10.5957C23.9453 10.5957 24.4043 11.0547 24.4043 11.6211C24.4043 12.1875 23.9453 12.6465 23.3789 12.6465Z"
                          fill="black"
                        />
                        <path
                          className="group-hover/item:fill-white fill-[#ffffffd4] transition-all ease-in duration-500 delay-0"
                          d="M25.7715 0H9.22852C4.13998 0 0 4.14005 0 9.22852V25.7715C0 30.86 4.14005 35 9.22852 35H25.7715C30.86 35 35 30.86 35 25.7715V9.22852C35 4.13998 30.86 0 25.7715 0ZM28.8477 22.6953C28.8477 26.0876 26.0876 28.8477 22.6953 28.8477H12.3047C8.91235 28.8477 6.15234 26.0876 6.15234 22.6953V12.3047C6.15234 8.91235 8.91235 6.15234 12.3047 6.15234H22.6953C26.0876 6.15234 28.8477 8.91235 28.8477 12.3047V22.6953Z"
                          fill="black"
                        />
                      </g>
                    </svg>
                  </a>
                  <a
                    href="mailto:maanar718@gmail.com"
                    className="flex flex-col-reverse justify-center group/item"
                  >
                    <div className="cursor-pointer group-hover/item:opacity-100 opacity-75 transition-all ease-in duration-150 delay-0">
                      E-Mail
                    </div>
                    <svg
                      className="h-10 transition-all items-center ease-in duration-150 delay-0 cursor-pointer"
                      viewBox="0 0 512 512"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_1569_2854)">
                        <path
                          className="group-hover/item:fill-white fill-[#ffffffd4] transition-all ease-in duration-150 delay-0"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M256 512C114.841 512 0 397.159 0 256C0 114.841 114.841 0 256 0C397.159 0 512 114.841 512 256C512 397.159 397.159 512 256 512ZM420.966 171.794V340.208C420.966 357.967 406.517 372.416 388.758 372.416H123.241C105.481 372.416 91.034 357.967 91.034 340.208V171.794C91.034 154.035 105.482 139.587 123.241 139.587H388.758C406.517 139.586 420.966 154.035 420.966 171.794Z"
                        />
                        <path
                          className="group-hover/item:fill-white fill-[#ffffffd4] transition-all ease-in duration-300 delay-0"
                          d="M392.804 344.251C391.731 345.325 390.275 345.928 388.758 345.93H123.241C121.723 345.928 120.268 345.325 119.194 344.251C118.121 343.178 117.517 341.723 117.516 340.205V179.488L247.765 282.92C250.176 284.834 253.088 285.792 255.999 285.792C258.91 285.792 261.823 284.835 264.233 282.92L394.483 179.488V340.205C394.481 341.723 393.877 343.178 392.804 344.251Z"
                        />
                        <path
                          className="group-hover/item:fill-white fill-[#ffffffd4] transition-all ease-in duration-500 delay-0"
                          d="M255.999 255.643L368.797 166.068H143.2L255.999 255.643Z"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1569_2854">
                          <rect width="512" height="512" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="snap-start scroll-mx-6 shrink-0 flex justify-center">
            <img
              class="w-60 md:w-64 lg:w-72 xl:w-80 h-auto rounded-lg shadow-xl bg-white object-cover drop-shadow-lg border border-neutral-300 dark:border-neutral-700"
              alt="Anujin"
              src={ankha}
            />
            <div className="absolute bottom-3 bg-[#40404040] text-white backdrop-blur w-56 md:w-60 lg:w-64 xl:w-72 rounded-lg flex flex-col justify-between items-center px-2 lg:px-4 py-1 lg:py-2 drop-shadow-lg gap-1">
              <div
                onClick={() => {
                  setN5(!n5);
                }}
                className="flex flex-row justify-between items-center w-full cursor-pointer"
              >
                <div className="drop-shadow-xl">
                  <div className="text-base lg:text-md xl:text-lg">
                    Ankhbayar Altansukh
                  </div>
                  <div className="text-xs xl:text-sm text-[#ffffffd4]">
                    Researcher, Statistician, Event organizer
                  </div>
                </div>
                <div>
                  <img
                    src={more}
                    className={
                      n5
                        ? "h-4 drop-shadow-lg opacity-75 transition-all ease-in duration-500 delay-50"
                        : "h-4 rotate-180 drop-shadow-lg opacity-75 transition-all ease-in duration-500 delay-50"
                    }
                    alt=""
                  />
                </div>
              </div>
              <div className="w-full text-sm text-center text-white transition-all ease-in duration-150 delay-0">
                <div
                  className={
                    n5
                      ? "h-14 flex flex-row w-full justify-between opacity-100 transition-all ease-in-out duration-700 delay-0"
                      : "flex flex-row w-full justify-between h-0 opacity-0 transition-all ease-in-out duration-700 delay-0"
                  }
                >
                  <a
                    href="https://www.facebook.com/ankhbayar.altansukh.9"
                    className="flex flex-col-reverse justify-center group/item"
                  >
                    <div className="cursor-pointer group-hover/item:opacity-100 opacity-75 transition-all ease-in duration-150 delay-0">
                      Facebook
                    </div>
                    <svg
                      className="h-10 transition-all items-center ease-in duration-150 delay-0 cursor-pointer"
                      viewBox="0 0 35 35"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className="group-hover/item:fill-white fill-[#ffffffd4] transition-all ease-in duration-300 delay-0"
                        d="M17.5001 0C7.83516 0 0 7.86792 0 17.5733C0 26.2778 6.30913 33.4867 14.5814 34.8826V21.2395H10.3599V16.3299H14.5814V12.7098C14.5814 8.5094 17.1362 6.22042 20.8682 6.22042C22.6556 6.22042 24.1917 6.35416 24.6376 6.41307V10.8036L22.0492 10.8049C20.02 10.8049 19.6288 11.773 19.6288 13.194V16.3274H24.4706L23.8391 21.237H19.6288V35C28.2873 33.9418 35 26.5493 35 17.5682C35 7.86792 27.1648 0 17.5001 0Z"
                      />
                    </svg>
                  </a>
                  <a
                    href="https://www.instagram.com/ankhaashka/"
                    className="flex flex-col-reverse justify-center group/item"
                  >
                    <div className="cursor-pointer group-hover/item:opacity-100 opacity-75  transition-all ease-in duration-150 delay-0">
                      Instagram
                    </div>
                    <svg
                      className="h-10 transition-all items-center ease-in duration-150 delay-0 cursor-pointer"
                      viewBox="0 0 35 35"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_1254_2470"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="35"
                        height="35"
                      >
                        <circle cx="17.5" cy="17.5" r="17.5" fill="white" />
                      </mask>
                      <g mask="url(#mask0_1254_2470)">
                        <path
                          className="group-hover/item:fill-white fill-[#ffffffd4] transition-all ease-in duration-150 delay-0"
                          d="M20.5762 17.5C20.5762 19.1989 19.1989 20.5762 17.5 20.5762C15.8011 20.5762 14.4238 19.1989 14.4238 17.5C14.4238 15.8011 15.8011 14.4238 17.5 14.4238C19.1989 14.4238 20.5762 15.8011 20.5762 17.5Z"
                          fill="black"
                        />
                        <path
                          className="group-hover/item:fill-white fill-[#ffffffd4] transition-all ease-in duration-300 delay-0"
                          d="M22.6953 8.20312H12.3047C10.0429 8.20312 8.20312 10.0429 8.20312 12.3047V22.6953C8.20312 24.9571 10.0429 26.7969 12.3047 26.7969H22.6953C24.9571 26.7969 26.7969 24.9571 26.7969 22.6953V12.3047C26.7969 10.0429 24.9571 8.20312 22.6953 8.20312ZM17.5 22.627C14.673 22.627 12.373 20.327 12.373 17.5C12.373 14.673 14.673 12.373 17.5 12.373C20.327 12.373 22.627 14.673 22.627 17.5C22.627 20.327 20.327 22.627 17.5 22.627ZM23.3789 12.6465C22.8125 12.6465 22.3535 12.1875 22.3535 11.6211C22.3535 11.0547 22.8125 10.5957 23.3789 10.5957C23.9453 10.5957 24.4043 11.0547 24.4043 11.6211C24.4043 12.1875 23.9453 12.6465 23.3789 12.6465Z"
                          fill="black"
                        />
                        <path
                          className="group-hover/item:fill-white fill-[#ffffffd4] transition-all ease-in duration-500 delay-0"
                          d="M25.7715 0H9.22852C4.13998 0 0 4.14005 0 9.22852V25.7715C0 30.86 4.14005 35 9.22852 35H25.7715C30.86 35 35 30.86 35 25.7715V9.22852C35 4.13998 30.86 0 25.7715 0ZM28.8477 22.6953C28.8477 26.0876 26.0876 28.8477 22.6953 28.8477H12.3047C8.91235 28.8477 6.15234 26.0876 6.15234 22.6953V12.3047C6.15234 8.91235 8.91235 6.15234 12.3047 6.15234H22.6953C26.0876 6.15234 28.8477 8.91235 28.8477 12.3047V22.6953Z"
                          fill="black"
                        />
                      </g>
                    </svg>
                  </a>
                  <a
                    href="ankhbayaraltansvkh@gmail.com"
                    className="flex flex-col-reverse justify-center group/item"
                  >
                    <div className="cursor-pointer group-hover/item:opacity-100 opacity-75 transition-all ease-in duration-150 delay-0">
                      E-Mail
                    </div>
                    <svg
                      className="h-10 transition-all items-center ease-in duration-150 delay-0 cursor-pointer"
                      viewBox="0 0 512 512"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_1569_2854)">
                        <path
                          className="group-hover/item:fill-white fill-[#ffffffd4] transition-all ease-in duration-150 delay-0"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M256 512C114.841 512 0 397.159 0 256C0 114.841 114.841 0 256 0C397.159 0 512 114.841 512 256C512 397.159 397.159 512 256 512ZM420.966 171.794V340.208C420.966 357.967 406.517 372.416 388.758 372.416H123.241C105.481 372.416 91.034 357.967 91.034 340.208V171.794C91.034 154.035 105.482 139.587 123.241 139.587H388.758C406.517 139.586 420.966 154.035 420.966 171.794Z"
                        />
                        <path
                          className="group-hover/item:fill-white fill-[#ffffffd4] transition-all ease-in duration-300 delay-0"
                          d="M392.804 344.251C391.731 345.325 390.275 345.928 388.758 345.93H123.241C121.723 345.928 120.268 345.325 119.194 344.251C118.121 343.178 117.517 341.723 117.516 340.205V179.488L247.765 282.92C250.176 284.834 253.088 285.792 255.999 285.792C258.91 285.792 261.823 284.835 264.233 282.92L394.483 179.488V340.205C394.481 341.723 393.877 343.178 392.804 344.251Z"
                        />
                        <path
                          className="group-hover/item:fill-white fill-[#ffffffd4] transition-all ease-in duration-500 delay-0"
                          d="M255.999 255.643L368.797 166.068H143.2L255.999 255.643Z"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1569_2854">
                          <rect width="512" height="512" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="snap-start scroll-mx-6 shrink-0 flex justify-center">
            <img
              class="w-60 md:w-64 lg:w-72 xl:w-80 h-auto rounded-lg shadow-xl bg-white object-cover drop-shadow-lg border border-neutral-300 dark:border-neutral-700"
              alt="Anujin"
              src={khulan}
            />
            <div className="absolute bottom-3 bg-[#40404040] text-white backdrop-blur w-56 md:w-60 lg:w-64 xl:w-72 rounded-lg flex flex-col justify-between items-center px-2 lg:px-4 py-1 lg:py-2 drop-shadow-lg gap-1">
              <div
                onClick={() => {
                  setN6(!n6);
                }}
                className="flex flex-row justify-between items-center w-full cursor-pointer"
              >
                <div className="drop-shadow-xl">
                  <div className="text-base lg:text-md xl:text-lg">
                    Khulan Munkhjargal
                  </div>
                  <div className="text-xs xl:text-sm text-[#ffffffd4]">
                    Content creator, Illustrator, Videographer
                  </div>
                </div>
                <div>
                  <img
                    src={more}
                    className={
                      n6
                        ? "h-4 drop-shadow-lg opacity-75 transition-all ease-in duration-500 delay-50"
                        : "h-4 rotate-180 drop-shadow-lg opacity-75 transition-all ease-in duration-500 delay-50"
                    }
                    alt=""
                  />
                </div>
              </div>
              <div className="w-full text-sm text-center text-white transition-all ease-in duration-150 delay-0">
                <div
                  className={
                    n6
                      ? "h-14 flex flex-row w-full justify-between opacity-100 transition-all ease-in-out duration-700 delay-0"
                      : "flex flex-row w-full justify-between h-0 opacity-0 transition-all ease-in-out duration-700 delay-0"
                  }
                >
                  <a
                    href="https://www.facebook.com/profile.php?id=100010676550291"
                    className="flex flex-col-reverse justify-center group/item"
                  >
                    <div className="cursor-pointer group-hover/item:opacity-100 opacity-75 transition-all ease-in duration-150 delay-0">
                      Facebook
                    </div>
                    <svg
                      className="h-10 transition-all items-center ease-in duration-150 delay-0 cursor-pointer"
                      viewBox="0 0 35 35"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className="group-hover/item:fill-white fill-[#ffffffd4] transition-all ease-in duration-300 delay-0"
                        d="M17.5001 0C7.83516 0 0 7.86792 0 17.5733C0 26.2778 6.30913 33.4867 14.5814 34.8826V21.2395H10.3599V16.3299H14.5814V12.7098C14.5814 8.5094 17.1362 6.22042 20.8682 6.22042C22.6556 6.22042 24.1917 6.35416 24.6376 6.41307V10.8036L22.0492 10.8049C20.02 10.8049 19.6288 11.773 19.6288 13.194V16.3274H24.4706L23.8391 21.237H19.6288V35C28.2873 33.9418 35 26.5493 35 17.5682C35 7.86792 27.1648 0 17.5001 0Z"
                      />
                    </svg>
                  </a>
                  <a
                    href="https://www.instagram.com/khln_mnh/"
                    className="flex flex-col-reverse justify-center group/item"
                  >
                    <div className="cursor-pointer group-hover/item:opacity-100 opacity-75  transition-all ease-in duration-150 delay-0">
                      Instagram
                    </div>
                    <svg
                      className="h-10 transition-all items-center ease-in duration-150 delay-0 cursor-pointer"
                      viewBox="0 0 35 35"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_1254_2470"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="35"
                        height="35"
                      >
                        <circle cx="17.5" cy="17.5" r="17.5" fill="white" />
                      </mask>
                      <g mask="url(#mask0_1254_2470)">
                        <path
                          className="group-hover/item:fill-white fill-[#ffffffd4] transition-all ease-in duration-150 delay-0"
                          d="M20.5762 17.5C20.5762 19.1989 19.1989 20.5762 17.5 20.5762C15.8011 20.5762 14.4238 19.1989 14.4238 17.5C14.4238 15.8011 15.8011 14.4238 17.5 14.4238C19.1989 14.4238 20.5762 15.8011 20.5762 17.5Z"
                          fill="black"
                        />
                        <path
                          className="group-hover/item:fill-white fill-[#ffffffd4] transition-all ease-in duration-300 delay-0"
                          d="M22.6953 8.20312H12.3047C10.0429 8.20312 8.20312 10.0429 8.20312 12.3047V22.6953C8.20312 24.9571 10.0429 26.7969 12.3047 26.7969H22.6953C24.9571 26.7969 26.7969 24.9571 26.7969 22.6953V12.3047C26.7969 10.0429 24.9571 8.20312 22.6953 8.20312ZM17.5 22.627C14.673 22.627 12.373 20.327 12.373 17.5C12.373 14.673 14.673 12.373 17.5 12.373C20.327 12.373 22.627 14.673 22.627 17.5C22.627 20.327 20.327 22.627 17.5 22.627ZM23.3789 12.6465C22.8125 12.6465 22.3535 12.1875 22.3535 11.6211C22.3535 11.0547 22.8125 10.5957 23.3789 10.5957C23.9453 10.5957 24.4043 11.0547 24.4043 11.6211C24.4043 12.1875 23.9453 12.6465 23.3789 12.6465Z"
                          fill="black"
                        />
                        <path
                          className="group-hover/item:fill-white fill-[#ffffffd4] transition-all ease-in duration-500 delay-0"
                          d="M25.7715 0H9.22852C4.13998 0 0 4.14005 0 9.22852V25.7715C0 30.86 4.14005 35 9.22852 35H25.7715C30.86 35 35 30.86 35 25.7715V9.22852C35 4.13998 30.86 0 25.7715 0ZM28.8477 22.6953C28.8477 26.0876 26.0876 28.8477 22.6953 28.8477H12.3047C8.91235 28.8477 6.15234 26.0876 6.15234 22.6953V12.3047C6.15234 8.91235 8.91235 6.15234 12.3047 6.15234H22.6953C26.0876 6.15234 28.8477 8.91235 28.8477 12.3047V22.6953Z"
                          fill="black"
                        />
                      </g>
                    </svg>
                  </a>
                  <a
                    href="mailto:khulan.mnh@gmail.com"
                    className="flex flex-col-reverse justify-center group/item"
                  >
                    <div className="cursor-pointer group-hover/item:opacity-100 opacity-75 transition-all ease-in duration-150 delay-0">
                      E-Mail
                    </div>
                    <svg
                      className="h-10 transition-all items-center ease-in duration-150 delay-0 cursor-pointer"
                      viewBox="0 0 512 512"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_1569_2854)">
                        <path
                          className="group-hover/item:fill-white fill-[#ffffffd4] transition-all ease-in duration-150 delay-0"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M256 512C114.841 512 0 397.159 0 256C0 114.841 114.841 0 256 0C397.159 0 512 114.841 512 256C512 397.159 397.159 512 256 512ZM420.966 171.794V340.208C420.966 357.967 406.517 372.416 388.758 372.416H123.241C105.481 372.416 91.034 357.967 91.034 340.208V171.794C91.034 154.035 105.482 139.587 123.241 139.587H388.758C406.517 139.586 420.966 154.035 420.966 171.794Z"
                        />
                        <path
                          className="group-hover/item:fill-white fill-[#ffffffd4] transition-all ease-in duration-300 delay-0"
                          d="M392.804 344.251C391.731 345.325 390.275 345.928 388.758 345.93H123.241C121.723 345.928 120.268 345.325 119.194 344.251C118.121 343.178 117.517 341.723 117.516 340.205V179.488L247.765 282.92C250.176 284.834 253.088 285.792 255.999 285.792C258.91 285.792 261.823 284.835 264.233 282.92L394.483 179.488V340.205C394.481 341.723 393.877 343.178 392.804 344.251Z"
                        />
                        <path
                          className="group-hover/item:fill-white fill-[#ffffffd4] transition-all ease-in duration-500 delay-0"
                          d="M255.999 255.643L368.797 166.068H143.2L255.999 255.643Z"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1569_2854">
                          <rect width="512" height="512" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-8 lg:mt-12 xl:mt-16 2xl:mt-20 flex justify-center flex-col items-center">
        <div className="text-[#4BAB4D]">Манай Түншүүд</div>
        <div className="text-2xl xl:text-3xl 2xl:text-4xl w-full text-center">
          Бид шилдэг хамтрагчидтай хамтран ажилладаг
        </div>
        <div class="flex flex-col sm:flex-row gap-3 lg:gap-6 mt-4 w-full justify-center items-center">
          <div className="w-full sm:w-52 lg:w-60 bg-neutral-100 dark:bg-neutral-950 border border-neutral-300 dark:border-neutral-700 py-2 rounded-lg shadow-xl flex justify-center">
            <img className="h-16 sm:h-auto" src={unicef} alt="" />
          </div>
          <div className="w-full sm:w-52 lg:w-60 bg-neutral-100 dark:bg-neutral-950 border border-neutral-300 dark:border-neutral-700 py-2 rounded-lg shadow-xl flex justify-center">
            <img className="h-16 sm:h-auto" src={mhz} alt="" />
          </div>
          <div className="w-full sm:w-52 lg:w-60 bg-neutral-100 dark:bg-neutral-950 border border-neutral-300 dark:border-neutral-700 py-2 rounded-lg shadow-xl flex justify-center">
            <img className="h-16 " src={gcouncil} alt="" />
          </div>
        </div>
      </div>
      <div className="mt-8 lg:mt-12 xl:mt-16 2xl:mt-20 w-full flex justify-center flex-col">
        <div className="text-2xl xl:text-3xl 2xl:text-4xl w-full flex justify-center">
          Бидний давуу талууд
        </div>
        <div className="mt-4 flex lg:flex-row flex-col w-full justify-center xl:gap-4 gap-2 text-neutral-800 dark:text-neutral-300">
          <div className="lg:w-96 bg-neutral-100 dark:bg-neutral-900 border border-neutral-300 dark:border-neutral-700 p-4 lg:p-6 xl:p-8 gap-1 lg:gap-2 flex flex-col rounded-lg drop-shadow">
            <div className="font-bold text-lg xl:text-xl">
              Төвлөрсөн нөөцийн төв
            </div>

            <div className="w-full text-justify text-sm lg:text-base">
              Бид манай орны өөрсдийн ирээдүйн төлөө хичээл зүтгэл гаргахыг
              эрмэлздэг хүүхэд, залуучуудад үнэ төлбөргүйгээр хэрэгцээт бүхий
              мэдээллүүдийг монгол хэл дээр цогц бүрэн шинэчилсээр хүргэх болно.
              Тухайлбал, ойртож буй стандартчилагдсан шалгалтуудын хуваарь,
              манай орны их сургуулийн өргөдөлд бэлдэхэд туслах сургалтуудын
              мэдээллүүд, мэргэжил бүрд тохирсон болон хийхийг зөвлөсөн
              хичээлээс гадуурх үйл ажиллагаанууд гэх мэт бүх чухал мэдээллийг
              нэг дор хадгалдаг. Энэ нь хэрэглэгчдэд олон эх сурвалжаас хайх
              шаардлагагүй болж, тэдний цаг хугацаа, хүчин чармайлтыг хэмнэнэ.
            </div>
          </div>
          <div className="lg:w-96 bg-neutral-100 dark:bg-neutral-900 border border-neutral-300 dark:border-neutral-700 p-4 lg:p-6 xl:p-8 gap-1 lg:gap-2 flex flex-col rounded-lg drop-shadow">
            <div className="font-bold text-lg xl:text-xl">
              Сайжруулсан хүртээмж, хүртээмжтэй байдал
            </div>

            <div className="w-full text-justify text-sm lg:text-base">
              Манай сайт нь Монгол Улсын нутаг дэвсгэрт байгаа газраас үл
              хамааран бүх хүүхэд, залуучуудад дээд боловсролын мэдээлэл авах
              боломжийг нэмэгдүүлнэ. Тухайлбал, хөдөө орон нутаг эсвэл
              алслагдсан бүс нутгийн залуус их дээд сургуулийн төлөөлөгчид эсвэл
              боловсролын үзэсгэлэнд оролцох боломж хязгаарлагдмал байдаг.
              Энэхүү сайт нь онлайнаар иж бүрэн мэдээллийг өгснөөр нийгэм, эдийн
              засгийн байдлаас үл хамааран бүх оюутнууд өргөдөл гаргах үйл явцыг
              үр дүнтэй ойлгох, удирдах тэгш боломжийг олгодог.
            </div>
          </div>
          <div className="lg:w-96 bg-neutral-100 dark:bg-neutral-900 border border-neutral-300 dark:border-neutral-700 p-4 lg:p-6 xl:p-8 gap-1 lg:gap-2 flex flex-col rounded-lg drop-shadow">
            <div className="font-bold text-lg xl:text-xl">
              Дэмжлэг ба удирдамж
            </div>

            <div className="w-full text-justify text-sm lg:text-base">
              Мэдээллийн хажуугаар манай сайт нь өдөр бүрийн биднээс өгөх
              зөвлөгөөний үгс, тодорхой хугацааны дагуу хийхийг зөвлөсөн зүйлс,
              хэрэгцээт файлууд, өөрсдийн нийтлэлийг түгээх боломжууд зэрэг
              дэмжлэг үзүүлэх үйлчилгээг санал болгодог. Түүнчлэн манай сайт нь
              өөрсдийн төлөвлөгөөг гаргаж, түүгээрээ мэдэгдэл авах боломжтой
              болсноор өргөдөл гаргах үйл явцыг хөнгөвчилж, хэрэглэгчдийн өөртөө
              итгэх итгэлийг нэмэгдүүлж, амжилттай элсэх боломжийг нэмэгдүүлэхэд
              тусална.
            </div>
          </div>
        </div>
      </div>
      <div className="fomt-semibold mt-8 lg:mt-12 xl:mt-16 2xl:mt-20 w-full flex justify-center flex-col">
        <div className="text-xl xl:text-2xl w-full">Түгээмэл асуултууд</div>
        <div class="hs-accordion-group flex flex-col mt-4 gap-2">
          <div
            class="hs-accordion hs-accordion-active:border-gray-200 bg-neutral-100 border border-transparent rounded-xl dark:hs-accordion-active:border-neutral-700 dark:bg-neutral-900 dark:border-transparent"
            id="hs-active-bordered-heading-one"
          >
            <button
              class="hs-accordion-toggle hs-accordion-active:text-[#037F00] inline-flex justify-between items-center gap-x-3 w-full font-semibold text-start text-gray-800 py-4 px-5 hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none  dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
              aria-controls="hs-basic-active-bordered-collapse-one"
            >
              Их, дээд сургуульд элсэхэд ямар шалгуурууд байдаг вэ?
              <svg
                class="hs-accordion-active:hidden block size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5v14"></path>
              </svg>
              <svg
                class="hs-accordion-active:block hidden size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
              </svg>
            </button>
            <div
              id="hs-basic-active-bordered-collapse-one"
              class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
              aria-labelledby="hs-active-bordered-heading-one"
            >
              <div class="pb-4 px-5">
                <p class="text-gray-800 dark:text-neutral-200">
                  <em>Элсэлтийн шаардлага</em> нь их сургууль, хөтөлбөрөөс
                  хамаарч өөр өөр байж болох ч ерөнхийдөө танд дараах зүйл
                  хэрэгтэй болно.
                </p>
                <ul class="list-disc mt-2 ml-4">
                  <li>Хичээлийн голч дүн.</li>
                  <li>
                    Хичээлээ үзэх хэлний оноо (тухайлбал, IELTS, JLPT, HSK эсвэл
                    улсаас хамаарч бусад).
                  </li>
                  <li>Эсээнүүд.</li>
                  <li>Бүрэн бөглөсөн өргөдлийн маягт.</li>
                </ul>
              </div>
            </div>
          </div>

          <div
            class="hs-accordion hs-accordion-active:border-gray-200 bg-neutral-100 border border-transparent rounded-xl dark:hs-accordion-active:border-neutral-700 dark:bg-neutral-900 dark:border-transparent"
            id="hs-active-bordered-heading-one"
          >
            <button
              class="hs-accordion-toggle hs-accordion-active:text-[#037F00] inline-flex justify-between items-center gap-x-3 w-full font-semibold text-start text-gray-800 py-4 px-5 hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none  dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
              aria-controls="hs-basic-active-bordered-collapse-one"
            >
              Би их дээд сургуульд элсэх өргөдлийг хэзээ эхлүүлэх вэ?
              <svg
                class="hs-accordion-active:hidden block size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5v14"></path>
              </svg>
              <svg
                class="hs-accordion-active:block hidden size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
              </svg>
            </button>
            <div
              id="hs-basic-active-bordered-collapse-one"
              class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
              aria-labelledby="hs-active-bordered-heading-one"
            >
              <div class="pb-4 px-5">
                <p class="text-gray-800 dark:text-neutral-200">
                  Та элсэхээр төлөвлөхөөс дор хаяж нэг жилийн өмнө их сургуульд
                  элсэх өргөдөл гаргах үйл явцыг эхлүүлэхийг зөвлөж байна. Энэ
                  цагийн хэлхээс танд дараах боломжийг олгоно:
                </p>
                <ul class="list-disc mt-2 ml-4">
                  <li>
                    Өөрт тохирно хэмээн үзэж буй их сургуулиуд болон тэдний
                    хөтөлбөрүүдийг сайтар судлах.
                  </li>
                  <li>
                    Хичээлээ үзэх хэлний оноо (тухайлбал, IELTS, JLPT, HSK эсвэл
                    улсаас хамаарч бусад).
                  </li>
                  <li>Эсээнүүд.</li>
                  <li>Бүрэн бөглөсөн өргөдлийн маягт.</li>
                </ul>
              </div>
            </div>
          </div>
          <div
            class="hs-accordion hs-accordion-active:border-gray-200 bg-neutral-100 border border-transparent rounded-xl dark:hs-accordion-active:border-neutral-700 dark:bg-neutral-900 dark:border-transparent"
            id="hs-active-bordered-heading-one"
          >
            <button
              class="hs-accordion-toggle hs-accordion-active:text-[#037F00] inline-flex justify-between items-center gap-x-3 w-full font-semibold text-start text-gray-800 py-4 px-5 hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none  dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
              aria-controls="hs-basic-active-bordered-collapse-one"
            >
              Хэрхэн тэтгэлэгт хамрагдах вэ?
              <svg
                class="hs-accordion-active:hidden block size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5v14"></path>
              </svg>
              <svg
                class="hs-accordion-active:block hidden size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
              </svg>
            </button>
            <div
              id="hs-basic-active-bordered-collapse-one"
              class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
              aria-labelledby="hs-active-bordered-heading-one"
            >
              <div class="pb-4 px-5">
                <p class="text-gray-800 dark:text-neutral-200">
                  <em>Тэтгэлэгт </em> хамрагдахын тулд дараах алхмуудыг дагах
                  хэрэгтэй:
                </p>
                <ul class="list-disc mt-2 ml-4">
                  <li>
                    Таны сонирхож буй их дээд сургуулиудад суралцах тэтгэлэг,
                    мөн байгууллагуудын санал болгож буй гадны тэтгэлэг байгаа
                    эсэхийг шалгах.
                  </li>
                  <li>Тэтгэлэг бүрийн шалгуур, эцсийн хугацааг харах.</li>
                  <li>
                    Хичээлийн жилийн эцсийн дүнгүүд, зөвлөмжийн захидал,
                    эсээнүүд, санхүүгийн хэрэгцээний нотлох баримт зэрэг
                    шаардлагатай бичиг баримтуудыг цуглуулах.
                  </li>
                  <li>
                    Тэтгэлэгт хамрагдах өргөдлөө заасан хугацаанд илгээж,
                    шаардлагатай бүх материалыг багтаасан эсэхийг шалгах.
                  </li>
                  <li>
                    Өргөдлөө илгээсний дараагаар нүүр туулах дараагийн алхмуудад
                    бэлэн байх.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
